import { Button } from '@mui/material';

type CookieButtonProps = {
    content: string;
    onClick: () => void;
};

export function CookieButton(props: CookieButtonProps) {
    const { onClick, content = '' } = props;
    return (
        <Button
            sx={{ mx: "5px", my: "7px" }}
            color="secondary"
            size="small"
            onClick={onClick}>
            {content}
        </Button>
    );
};