import { useEffect, useState } from "react";
import { Grid, List, Typography, ListItem, Divider } from "@mui/material";
import { CustomerInfo, CustomerLoginInfo } from "orderme-api-integration-client";
import { DialogProps } from "../../../interfaces/interfaces"
import { Popup } from "../../popup/popup"
import { RootState } from "../../../redux/store";
import { updateCustomerInfo } from "../../../redux/reducers/customerReducer";
import { setCustomerNumber } from "../../../helpers/localStorageService";
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

export function CustomerSwitch(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, open } = props;
  const [customers, setCustomers] = useState<CustomerInfo[]>(null);
  const customerLoginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);
  const { t } = useTranslation('account');

  useEffect(() => {
    setCustomers(customerLoginInfo.customers);
  }, [customerLoginInfo]);

  const switchCustomer = (customer: CustomerInfo, customerNumber: number) => {
    setCustomerNumber(customerNumber);
    dispatch(updateCustomerInfo(customer));
    onClose();
  }

  return (
    <Popup onClose={() => onClose()} open={open} dialogTitle={t('changeCustomer')} dialogContent={
      <List>
        {customers?.map((customer, index) => {
          return (
            <div>
              <ListItem button key={index} onClick={() => switchCustomer(customer, index)}>
                <Grid container justifyContent="space-between">
                  <Typography>{customer.name} {customer.surname}</Typography>
                </Grid>
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
    } dialogActions={<div></div>}
    />
  );
}