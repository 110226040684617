import { TextField, ListItemIcon, ListItem, Grid } from "@mui/material";

interface InputFieldProps {
    inputValue?: string;
    fieldName?: string;
    icon: any;
    handleFieldChange?: (e: any) => void;
    isPassword?: boolean;
    isDate?: boolean;
    endAdornment?: JSX.Element;
    selectComp?: any;
    label?: string;
    isGender?: boolean;
    placeholder?: string;
    fieldsDisabled?: boolean;
    open?: () => void;
    isReadOnly?: boolean;
    additionalComp?: JSX.Element;
    disableGutters?: boolean;
}

export function InputField(props: InputFieldProps) {
    const { inputValue, icon, fieldName, label, handleFieldChange, isPassword, endAdornment, isGender,
        selectComp, placeholder, additionalComp, isReadOnly, fieldsDisabled, open, disableGutters = false,
        isDate } = props;

    return (
        <ListItem disableGutters={disableGutters}>
            <ListItemIcon sx={{ color: "paulini.orange", paddingTop: "15px" }}>
                {icon}
            </ListItemIcon>
            <Grid container direction="row">
                <TextField sx={{ width: "100%" }}
                    onClick={() => isReadOnly && !fieldsDisabled && open()}
                    select={isGender ? true : false}
                    type={isPassword ? "password" : isDate ? "date" : "text"}
                    name={fieldName}
                    label={label}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleFieldChange}
                    disabled={fieldsDisabled}
                    InputProps={{ readOnly: isReadOnly, endAdornment: endAdornment }}
                    InputLabelProps={isDate && { shrink: true }}
                    variant="standard">
                    {selectComp}
                </TextField>
                {additionalComp}
            </Grid>
        </ListItem>
    );
}