import { Grid, Typography, Box, CircularProgress, MenuItem } from "@mui/material";
import { NavBar } from "../appBars/navBar/navBar";
import { useTranslation } from 'react-i18next';
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { ContactRequest, ContactTarget, ContactTargetType, CustomerLoginInfo, FileParameter } from "orderme-api-integration-client";
import { CustomerClient, FileClient } from "../../helpers/client";
import { isValidEmail, isValidPhoneNumber } from "../../helpers/validator";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { InputTextField } from "./supportStyles";
import { MuiFileInput } from 'mui-file-input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function Support() {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isRegistering, setIsRegistering] = useState(false);
    const customersClient: CustomerClient = new CustomerClient();
    const filesClient: FileClient = new FileClient();
    const [topics, setTopics] = useState<ContactTarget[]>();
    const [topic, setTopic] = useState<ContactTarget>(null);
    const [orderId, setOrderId] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [content, setContent] = useState("");
    const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const loginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);
    const [messageSent, setMessageSent] = useState(false);
    const [error, setError] = useState("");
    const [attachment, setAttachment] = useState<File | null>(null);

    useEffect(() => {
        getTopics();
    }, []);

    useEffect(() => {
        if (isUserLoggedIn) {
            setEmail(loginInfo.email);
            setPhone(loginInfo.phone);
        }
        else {
            setEmail("");
            setPhone("");
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        console.debug("validate");
        validate();
    }, [topic, content, email, phone, orderId]);

    const getTopics = () => {
        customersClient.getContactTargets().then((response) => {
            setTopics(response);
            if (response?.length > 0)
                setTopic(response[0]);
        }).catch((err) => {
            console.log(err);
        });
    }

    const registerTicket = async () => {
        if (!isValid())
            return;

        setIsRegistering(true);
        setError("");

        try {
            const contactRequest = {
                targetId: topic.id,
                orderNo: topic.id === ContactTargetType.Claims ? orderId : undefined,
                email: email,
                phone: phone,
                content: content,
            } as ContactRequest;

            if (attachment) {
                const response = await filesClient.postFile({ data: attachment, fileName: attachment.name } as FileParameter);
                contactRequest.attachmentIds = [response];
            }

            await customersClient.contact(contactRequest);
            setIsRegistering(false);
            setMessageSent(true);
        } catch (err) {
            console.log(err);
            setIsRegistering(false);
            setMessageSent(false);
            setError(t("support:messageSendError"));
        }
    }

    const handleEmailChange = (e: any) => {
        const value = e.target.value;
        setEmail(value);
        if (!value || isValidEmail(value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const handlePhoneChange = (e: any) => {
        const value = e.target.value;
        setPhone(value);
        if (!value || isValidPhoneNumber(value)) {
            setPhoneError(false);
        } else {
            setPhoneError(true);
        }
    };

    const validate = () => {
        setIsButtonDisabled(!isValid());
    }

    const isValid = (): boolean => {
        if (!topic)
            return false;
        else if (topic.id === ContactTargetType.Claims && !orderId)
            return false;

        if (!content)
            return false;

        if (!email)
            return false;

        if (!phone)
            return false;

        return !emailError && !phoneError;
    }

    const handleFileChange = (newFile: File) => {
        setAttachment(newFile);
    }

    return (
        <Grid container direction="column" alignItems="center" sx={{ overflow: "hidden" }}>
            <NavBar barTitle={t('info:contactUs')} />
            <BreadcrumbsBar to={"support"} />
            <Box
                sx={{
                    maxWidth: "1000px",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    color: "black",
                    mt: appStyleMode !== AppStyle.Mobile ? "10px" : null,
                    py: appStyleMode !== AppStyle.Mobile ? "20px" : null
                }}
                className={appStyleMode === AppStyle.Mobile && "support-container"}
            >
                {appStyleMode !== AppStyle.Mobile &&
                    <Typography sx={{ mx: "20px" }} variant="h5" fontWeight="bold">{t("support:pageTitle")}</Typography>
                }
                {!messageSent &&
                    <Grid sx={{ px: "20px" }} container direction="column">
                        <Typography sx={{ my: "10px" }}>{t("support:pageDescription")}</Typography>
                        {topics &&
                            (<InputTextField
                                select
                                value={topic?.id}
                                label={t("support:selectTarget")}>
                                {topics?.map((target) => {
                                    return (
                                        <MenuItem key={target.id} value={target.id} onClick={() => setTopic(target)}>
                                            {target.title}
                                        </MenuItem>
                                    );
                                })}
                            </InputTextField>)
                        }
                        {topic?.id === ContactTargetType.Claims &&
                            <InputTextField
                                label={t("support:orderId")}
                                value={orderId}
                                onChange={(e) => setOrderId(e.target.value)}
                            />
                        }
                        <InputTextField
                            label={t("support:yourMessage")}
                            multiline
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            rows={5}
                        />
                        <InputTextField
                            label={t("support:email")}
                            value={email}
                            onChange={handleEmailChange}
                            error={emailError}
                            helperText={emailError ? t("support:invalidEmail") : ""}
                        />
                        <InputTextField
                            placeholder="+370xxxxxxxx"
                            label={t("support:phone")}
                            value={phone}
                            onChange={handlePhoneChange}
                            error={phoneError}
                            helperText={phoneError ? t("support:invalidPhone") : ""}
                        />
                        <MuiFileInput
                            sx={{ my: "10px" }}
                            placeholder={t("support:attachFile")}
                            value={attachment}
                            onChange={handleFileChange}
                            InputProps={{
                                inputProps: {
                                    accept: '.png, .jpg, .jpeg, .gif, .tiff'
                                },
                                startAdornment: <AttachFileIcon />
                            }}
                            clearIconButtonProps={{
                                title: t("support:remove"),
                                children: <CloseIcon fontSize="small" />
                            }}
                        />
                        {error && <Typography color="paulini.error">{error}</Typography>}
                        <Box sx={{ width: "auto", margin: "10px auto" }}>
                            <LoadingButton
                                disabled={isButtonDisabled}
                                onClick={registerTicket}
                                loading={isRegistering}
                                loadingIndicator={<CircularProgress sx={{ color: "paulini.blue" }} />}
                                variant="contained"
                            >
                                <Box sx={{ color: "white" }}>{t("support:send")}</Box>
                            </LoadingButton>
                        </Box>
                    </Grid>
                }
                {messageSent &&
                    <Typography sx={{ mx: "20px", mt: "20px" }} >{t("support:messageSentSuccessfully")}</Typography>
                }
            </Box>
        </Grid>
    );
}