import { useEffect, useRef, useState } from "react";
import { ImageList, Grid, Card, ListItemButton } from "@mui/material";
import { CreditServiceItem, CustomerInfo, NewCreditsOrder, NewOrder, NewServiceItem, Order, OrderDestination, OrderStatus, PaymentMethod, ServiceItem, PaymentMethodType }
  from "orderme-api-integration-client";
import { OrderClient, PaymentClient, } from "../../helpers/client"
import { useNavigate, useLocation } from "react-router";
import { NavBar } from "../appBars/navBar/navBar";
import { Linear } from "../progressBars/linear"
import { RootState } from "../../redux/store";
import { LoginDialog } from "../auth/login/loginDialog";
import { PaymentStatus } from "./paymentEnum";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import { PaymentDialog } from "./paymentDialog";
import { ImageLoader } from "../imgLoader/imgLoader";
import { getCredits, getCreditsUsed, getFullPrice, isFreeDelivery } from "../../helpers/costCounter";
import { useTranslation } from 'react-i18next';
import { PaymentConfirmDialog } from "./paymentConfirmDialog";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { clearCart } from "../../redux/reducers/cartReducer";
import { clearDelivery } from "../../redux/reducers/pickupReducer";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { MissingPayments } from "./missingPayments";

declare let window: any;
declare let ApplePaySession: any;
declare let googlePayClient: google.payments.api.PaymentsClient;
let inAppBrowserRef: any;

export interface PaymentParam {
  orderId?: string;
  creditService?: CreditServiceItem;
}

export function Payments() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const paymentMethodsClient: PaymentClient = new PaymentClient();
  const ordersClient: OrderClient = new OrderClient();
  const [openLogin, setOpenLogin] = useState(false);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState<number>(8000);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>();
  const [isPaymentsDisabled, setIsPaymentDisabled] = useState<boolean>(false);
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
  const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
  const orderDestination: OrderDestination = useAppSelector((state: RootState) => state.pickupState.orderDestination);
  const deliveryService: ServiceItem = useAppSelector((state: RootState) => state.pickupState.deliveryService);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [allPaymentMethods, setAllPaymentMethods] = useState<PaymentMethod[]>();
  const paymentTimeoutRef = useRef<number>(0);
  const [missingPayments, setMissingPayments] = useState(false);
  const [isGooglePayEnabled, setIsGooglePayEnabled] = useState(false);
  const isApplePayEnabled = window.ApplePaySession && (window.cordova || ApplePaySession.canMakePayments());

  const { t } = useTranslation('payment');

  const location = useLocation();
  const paymentParam = location.state as PaymentParam;
  const orderId = paymentParam?.orderId;
  const creditService = paymentParam?.creditService;

  const googleIsReadyToPayRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY'
        },
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["VISA", "MASTERCARD"]
        }
      }
    ]
  } as google.payments.api.IsReadyToPayRequest;

  useEffect(() => {
    if (!isUserLoggedIn) {
      setOpenLogin(true);
      return;
    }

    if (orderId) {
      payForOrder();
    }
    else if (creditService) {
      getPayments();
    }
    else {
      const totalToPay = getTotalToPay();
      if (totalToPay) {
        const credits = getCredits();
        if (credits > 0 && totalToPay <= credits) {
          setOpenConfirmDialog(true);
        }
        else {
          getPayments();
        }
      }
      else {
        navigate('/cart', { replace: true });
      }
    }

    return () => {
      clearTimeout(paymentTimeoutRef.current);
      paymentTimeoutRef.current = -1;
    };
  }, []);

  const getPayments = () => {
    if (googlePayClient) {
      googlePayClient
        .isReadyToPay(googleIsReadyToPayRequest)
        .then((res) => {
          if (res.result) {
            setIsGooglePayEnabled(true);
          } else {
            setIsGooglePayEnabled(false);
          }
        }).catch((err) => {
          console.error(err);
          setIsGooglePayEnabled(false);
        })
    }

    paymentMethodsClient.get().then((response) => {
      if (response?.length > 0) {
        setAllPaymentMethods(response);
      }
      else {
        setMissingPayments(true);
      }
    }).catch((err) => {
      console.log(err);
      setMissingPayments(true);
    })
  };

  useEffect(() => {
    if (!allPaymentMethods)
      return;

    var enabledPayments = allPaymentMethods.filter(isPaymentEnabled);
    setPaymentMethods(enabledPayments);
    if (enabledPayments.length === 0)
      setMissingPayments(true);
  }, [allPaymentMethods, isGooglePayEnabled, isApplePayEnabled]);

  const isPaymentEnabled = (payment: PaymentMethod) => {
    if (payment.id === "turnedof")
      return false;

    if (payment.type === PaymentMethodType.ApplyPay)
      return isApplePayEnabled;

    if (payment.type === PaymentMethodType.GooglePay)
      return isGooglePayEnabled;

    return true;
  }

  const payForOrder = async (paymentMethodId: string = undefined) => {
    setIsPaymentDisabled(true);
    setPaymentStatus(PaymentStatus.Loading);
    setOpenPaymentDialog(true);

    try {
      if (orderId) {
        getOrderInfo(orderId);
        return;
      }

      let response;
      if (creditService) {
        const creditsOrder = {
          paymentMethodId: paymentMethodId,
          expectedPrice: creditService.price,
          expectedCredits: creditService.credits
        } as NewCreditsOrder;
        response = await ordersClient.post2(userInfo.id, creditsOrder);
      }
      else {
        let newOrder = {
          paymentMethodId: paymentMethodId,
          destination: orderDestination,
          note: undefined,
          services: createCartData(),
          creditsUsed: getCreditsUsed(true),
          expectedPrice: getTotalToPay()
        } as NewOrder;

        response = await ordersClient.post(userInfo.id, newOrder);
      }

      if (!paymentMethodId) {
        setCloseTimeout(4000);
        setPaymentStatus(PaymentStatus.Success);
        setOpenPaymentDialog(true);
        assignCustomerDetails();
      }
      else {
        getOrderInfo(response.id);
      }
    }
    catch (err) {
      setIsPaymentDisabled(false);
      setPaymentStatus(PaymentStatus.FailedSubmitOrder);
      console.log(err);
    }
  }

  const getOrderInfo = (orderId: string) => {
    ordersClient.get2(userInfo.id, orderId).then((response) => {
      checkForStatus(response);
    }).catch((error) => {
      allowPaymentsOnFailure()
      console.log(error);
    }
    );
  }

  const allowPaymentsOnFailure = () => {
    setIsPaymentDisabled(false);
    setPaymentStatus(PaymentStatus.Failure);
  }

  const checkForStatus = (orderInfo: Order) => {
    if (orderInfo.status === OrderStatus.WaitingForPayment) {
      openCheckout(orderInfo.payment.checkoutUrl);
      waitForPayment(orderInfo.id, orderInfo.payment.isActive);
      assignCustomerDetails();
    }
    else {
      setTimeout(function () {
        getOrderInfo(orderInfo.id);
      }, 1500);
    }
  }

  const openCheckout = (url: string) => {
    if (window.cordova) {
      inAppBrowserRef = window.cordova.InAppBrowser.open(url, '_blank', 'location=no');
    }
    else {
      dispatch(clearCart());
      dispatch(clearDelivery());
      if (appStyleMode === AppStyle.Mobile)
        window.open(url, '_blank');
      else
        window.location.replace(url);
    }
  }

  function waitForPayment(orderId: string, isActivated: boolean) {
    async function continueWaiting() {
      try {
        let payment = await ordersClient.getPayment(userInfo.id, orderId);
        // If payment is not activated yet, we need to wait until will be activated
        if (!isActivated) {
          isActivated = payment.isActive;
          paymentTimeoutRef.current = setTimeout(continueWaiting, 2000) as any;
          return;
        }

        if (payment.isActive) {
          paymentTimeoutRef.current = setTimeout(continueWaiting, 2000) as any;
          return;
        }

        if (payment.paidOn) {
          loadPaymentInfo(PaymentStatus.Success);
        }
        else {
          loadPaymentInfo(PaymentStatus.Failure);
        }
      }
      catch (error) {
        console.error('Error fetching order info:', error);
      }
    }

    continueWaiting();
  }

  const loadPaymentInfo = (status: PaymentStatus) => {
    setPaymentStatus(status);
    setOpenPaymentDialog(true);

    if (window.cordova) {
      setTimeout(function () {
        inAppBrowserRef.close();
      }, 4000);
    }
  }

  const createCartData = () => {
    let newServiceItems: NewServiceItem[] = [];
    const services = servicesInBasket.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    services.forEach(service => {
      let count = servicesInBasket.filter(serviceContent => serviceContent.id === service.id).length
      newServiceItems.push({ id: service.id, count: count } as NewServiceItem);
    })

    if (!isFreeDelivery()) {
      newServiceItems.push({ id: deliveryService.id, count: 1 } as NewServiceItem);
    }

    return newServiceItems;
  }

  function getTotalToPay(): number {
    return getFullPrice(true);
  }

  const closePaymentDialog = () => {
    setOpenPaymentDialog(false);
  }

  const closeLogin = () => {
    setOpenLogin(false);

    if (!isUserLoggedIn) {
      navigate(-1);
    }
  }

  const closeConfirmDialog = (isConfirmed: boolean) => {
    if (isConfirmed) {
      payForOrder();
    }
    else {
      navigate('/home', { replace: true });
    }

    setOpenConfirmDialog(false);
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('paymentMethods')} />
      {!paymentMethods && !missingPayments && (<Linear />)}
      <BreadcrumbsBar to="cart/paymentMethods" />
      {paymentMethods &&
        (<ImageList
          rowHeight="auto"
          sx={{
            height: appStyleMode !== AppStyle.Mobile ? "auto" : null,
            mt: "5px",
            maxWidth: appStyleMode !== AppStyle.Mobile ? "500px" : null,
            width: "100%"
          }}
          className={appStyleMode === AppStyle.Mobile && "payment-methods-list"}
          cols={1}>
          {paymentMethods.map((paymentMethod, index) => {
            return (
              <Grid key={index} container justifyContent="center">
                <Card sx={{ width: "100%", height: "70px", m: "10px" }}>
                  <ListItemButton sx={{ height: "100%" }} disabled={isPaymentsDisabled} onClick={() => payForOrder(paymentMethod.id)}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      {paymentMethod.logoUrls.map(url => {
                        return (
                          <ImageLoader
                            key={url}
                            sx={{ height: "60px", marginLeft: "5px" }}
                            src={url}
                            alt={paymentMethod.name} />);
                      })}
                    </Grid>
                  </ListItemButton>
                </Card>
              </Grid>);
          })}
        </ImageList>)
      }
      {missingPayments && <MissingPayments />}
      <LoginDialog open={openLogin} onClose={() => closeLogin()} />
      <PaymentDialog open={openPaymentDialog} onClose={closePaymentDialog} paymentStatus={paymentStatus} timeout={closeTimeout} />
      <PaymentConfirmDialog open={openConfirmDialog} onClose={closeConfirmDialog} amount={getTotalToPay()} />
    </Grid >
  );
}