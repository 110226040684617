import { roundDoubleNumbers } from "../../helpers/currency"
import { AppBarTypography } from "./shoppingCartStyles"
import { cartWorth } from "../../helpers/costCounter";
import { useAppSelector } from "../../redux/hooks";
import { CartBarBadgeIcon } from "./cartBarBadgeIcon";

export function CartBarBadgeItem() {
    const price: number = useAppSelector(() => cartWorth());

    return (
        <>
            <CartBarBadgeIcon />
            <AppBarTypography> {roundDoubleNumbers(price)} €</AppBarTypography>
        </>
    );
}