import { createRef, useEffect, useState } from "react";
import { Divider, IconButton, ListItemText, ListItem, Grid, Box, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addToCart } from "../../redux/reducers/cartReducer";
import { roundDoubleNumbers } from "../../helpers/currency"
import { FilteredItemProps } from "../../interfaces/interfaces"
import { ServiceItem } from "orderme-api-integration-client";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import { ProductPrices } from "../productCard/productPrices";
import { RootState } from "../../redux/store";
import { getServicePrice } from "../../helpers/costCounter";
import FriendsBadge from "../productCard/friendsBadge";

export function FilteredItem(props: FilteredItemProps) {
    const dispatch = useAppDispatch();
    const { service } = props;
    const titleRef = createRef<any>()
    const { t } = useTranslation('services');
    const [isPricesVisible, setIsPricesVisible] = useState(false);
    const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
    const serviceCount: number = servicesInBasket.filter(s => s.id === service.id).length;
    const servicePrice: number = getServicePrice(service, serviceCount);
    const isLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const hasAmountPrice = service.prices?.length > 0;
    const isPriceForLoggedInUser = !hasAmountPrice && service.originalPrice > servicePrice;
    const showFriendsPrice = !isLoggedIn && isPriceForLoggedInUser;

    const addProductToCart = (service: ServiceItem) => {
        dispatch(addToCart(service));
        if (appStyleMode === AppStyle.Mobile)
            titleRef.current.scrollIntoView({ behavior: 'smooth', block: "center" });
    }

    const togglePricesVisible = () => {
        setIsPricesVisible(!isPricesVisible);
    }

    useEffect(() => {
    }, []);

    return (
        <Grid>
            <ListItem ref={titleRef}>
                <ListItemText
                    primary={
                        <Grid container
                            direction={{ xs: "column", sm: "row" }}
                            textAlign={{ xs: "center", sm: "left" }}
                            justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} lg={9}>
                                {service.name}
                            </Grid>
                            <Grid item xs={6} lg={3} sx={{ width: { xs: "240px", lg: "auto" } }}>
                                {
                                    hasAmountPrice &&
                                    <Grid container direction="row" alignItems="center" justifyContent="flex-end"
                                        sx={{
                                            backgroundColor: "white",
                                            pr: "15px"
                                        }} >
                                        <Typography alignItems="right" sx={{ fontSize: "14px" }}>{t("bulkDiscount")}</Typography>
                                        <InfoIcon sx={{ color: "paulini.blue" }} onClick={togglePricesVisible} />
                                    </Grid>
                                }
                                <Grid container direction="row" alignItems="center" justifyContent="right">
                                    {!showFriendsPrice &&
                                        <>
                                            {service.originalPrice > servicePrice &&
                                                <Box color="text.disabled" sx={{ textDecoration: "line-through", mr: "10px" }}>
                                                    {roundDoubleNumbers(service.originalPrice)} €
                                                </Box>
                                            }
                                            <Box>
                                                {roundDoubleNumbers(servicePrice)} €
                                            </Box>
                                        </>
                                    }
                                    {showFriendsPrice &&
                                        <Grid textAlign="right">
                                            <Box>
                                                {roundDoubleNumbers(service.originalPrice)} €
                                            </Box>
                                            <Grid container>
                                                <FriendsBadge />
                                                <Box sx={{ ml: "5px", fontWeight: "bold", width: "80px" }}>
                                                    {roundDoubleNumbers(servicePrice)} €
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    <IconButton sx={{
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        }
                                    }}
                                        onClick={() => addProductToCart(service)}>
                                        <AddCircleOutlineIcon sx={{ color: "paulini.orange", height: "35px", width: "35px" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                />
            </ListItem>
            <Divider />
            {hasAmountPrice &&
                <ProductPrices serviceName={service.name} prices={service.prices} open={isPricesVisible} onClose={togglePricesVisible} />
            }
        </Grid>
    );
}