import { forwardRef, Ref, useImperativeHandle, useRef, useState } from "react";
import { Grid, Collapse, Typography } from "@mui/material";
import { Address, CustomerAddress, CustomerInfo, Municipality } from "orderme-api-integration-client";
import { RootState } from "../../redux/store";
import { MunicipalityItem } from "./municipalityItem";
import { StreetItem } from "./streetItem";
import { AddressBookRef, NewAddressProps } from "../../interfaces/interfaces";
import { ApartmentNoCard, ApartmentNoInput, NewAddressContainer } from "./addressBookStyles";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { GreenButton, RedButton } from "../../controls/button/buttonStyles";

export const NewAddress = forwardRef((props: NewAddressProps, ref: Ref<AddressBookRef>) => {
  const [selectedMunicipality, setSelectedMunicipality] = useState<Municipality>();
  const [apartmentNo, setApartmentNo] = useState<string>('');
  const [newAddress, setNewAddress] = useState<Address>();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const MunicipalityRef = useRef<AddressBookRef>(null);
  const StreetRef = useRef<AddressBookRef>(null);
  const { updateAddresses } = props;
  const { t } = useTranslation('address');

  useImperativeHandle(ref, () => ({ clearValues }));

  const clearValues = () => {
    MunicipalityRef.current.clearValues();
    StreetRef.current.clearValues();
    setApartmentNo('');
  }

  const manageAlert = () => {
    setIsAlertOpen(true);

    setTimeout(function () {
      setIsAlertOpen(false);
    }, 2000);
  }

  const addNewAddress = () => {
    if (userInfo.addresses.some(a => isNewAddressEqual(a.address))) {
      manageAlert();
      return;
    }

    var customerAddresses: CustomerAddress[] = userInfo.addresses.map(item => item.address);

    var newAdd = {
      address: newAddress.fullAddress,
      apartmentNumber: apartmentNo !== '' ? apartmentNo : undefined,
    } as CustomerAddress;

    customerAddresses.push(newAdd);

    updateAddresses(customerAddresses);
  }

  const isNewAddressEqual = (customerAddress: CustomerAddress) => {
    if (!customerAddress || !newAddress)
      return false;

    if (customerAddress.address !== newAddress.fullAddress)
      return false;

    if (!apartmentNo && !customerAddress.apartmentNumber)
      return true;

    return customerAddress.apartmentNumber === apartmentNo;
  }

  const canPress = () => {
    return selectedMunicipality && newAddress;
  }

  return (
    <NewAddressContainer container direction="column" alignItems="center">
      <MunicipalityItem ref={MunicipalityRef} setSelectedMunicipality={setSelectedMunicipality} selectedMunicipality={selectedMunicipality} />
      <StreetItem ref={StreetRef} municipality={selectedMunicipality} setNewAddress={setNewAddress} />
      <ApartmentNoCard>
        <ApartmentNoInput
          placeholder={t('apartmentNoInput')}
          onChange={(e: any) => setApartmentNo(e.target.value)}
          value={apartmentNo} />
      </ApartmentNoCard>

      <Collapse sx={{ marginTop: "10px" }} in={isAlertOpen}>
        <Typography sx={{ color: "paulini.error" }}>{t('addressExist')}</Typography>
      </Collapse>

      <Grid container direction="row" justifyContent="space-between" sx={{ mt: "10px" }}>
        <RedButton onClick={() => clearValues()} variant="contained">{t('clearValues')}</RedButton>
        <GreenButton onClick={() => addNewAddress()} disabled={!canPress()} variant="contained">{t('add')}</GreenButton>
      </Grid>
    </NewAddressContainer>
  );
});