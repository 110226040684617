import { List } from "@mui/material";
import { styled } from '@mui/system';

type ListStyledProps = {
   isMobile: boolean
}

export const ListStyled = styled(List)<ListStyledProps>(({ isMobile }) => ({
   paddingTop: 0,
   paddingBottom: 0,
   width: "100%",
   maxWidth: !isMobile && "1000px",
   backgroundColor: "white",
   borderRadius: "15px",
   height: isMobile ? window.innerHeight - 56 : "auto",
   overflow: isMobile && "auto"
}));