export const roundDoubleNumbers = (totalPrice: number) => {
    if(totalPrice === 0){
        return '0.00'
    }
    
    if(totalPrice){
        let num = totalPrice;
        let roundedString = num.toFixed(2);
        return roundedString;
    }
}