import CheckIcon from "../../logo/check.png"
import { styled } from '@mui/system';
import { Box } from "@mui/material";

interface CheckBoxProps {
  isChecked: boolean,
  backgroundColor?: string,
  onChange: () => void;
}

type ImageProps = {
  isChecked: boolean
}

export const ImgStyled = styled('img')<ImageProps>(({ isChecked }) => ({
  visibility: isChecked ? "visible" : "hidden",
  opacity: isChecked ? 1 : 0,
  height: "30px",
  position: "absolute",
  top: "-6px",
  left: "-3px",
  transition: "visibility 0s, opacity 0.2s linear"
}));

export function CheckBox(props: CheckBoxProps) {
  const { isChecked, onChange, backgroundColor } = props;

  return (
    <Box onClick={() => onChange()}
      sx={{
        border: "solid 3px",
        borderRadius: "5px",
        backgroundColor: backgroundColor,
        borderColor: "paulini.orange",
        position: "relative",
        height: "20px",
        width: "20px"
      }}>
      <ImgStyled isChecked={isChecked} src={CheckIcon} />
    </Box>
  );
}