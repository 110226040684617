import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeliveryType, OrderDestination, ServiceItem } from "orderme-api-integration-client";

export interface DeliveryState {
    orderDestination: OrderDestination,
    deliveryType: DeliveryType,
    freeDeliveryFrom: number,
    deliveryService: ServiceItem
}

const initialState = {
    orderDestination: {} as OrderDestination,
    freeDeliveryFrom: 0,
    deliveryService: null as ServiceItem,
    deliveryType: null as DeliveryType
}

const pickupSlice = createSlice({
    name: 'pickup',
    initialState: initialState,
    reducers: {
        deliverToSalesPoint: (state, action: PayloadAction<OrderDestination>) => {
            console.debug(action.payload);
            if (action.payload)
                state.orderDestination = action.payload;
            else
                state.orderDestination = {} as OrderDestination;

            state.deliveryType = DeliveryType.SalesPoint;
        },
        deliverToAddress: (state, action: PayloadAction<OrderDestination>) => {
            state.orderDestination = action.payload;
            state.deliveryType = DeliveryType.Address;
        },
        deliverToBusinessLocker: (state, action: PayloadAction<OrderDestination>) => {
            state.orderDestination = action.payload;
            state.deliveryType = DeliveryType.Wardrobe;
        },
        updateDeliveryService: (state, action: PayloadAction<ServiceItem>) => {
            state.deliveryService = action.payload;
        },
        updateFreeDeliveryFrom: (state, action: PayloadAction<number>) => {
            state.freeDeliveryFrom = action.payload;
        },
        clearDelivery: () => initialState
    },
})

export const
    {
        deliverToSalesPoint,
        deliverToAddress,
        deliverToBusinessLocker,
        updateDeliveryService,
        updateFreeDeliveryFrom,
        clearDelivery
    } = pickupSlice.actions
export default pickupSlice.reducer