import { Box, Button, Grid, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { cartWorth } from "../../helpers/costCounter";

interface WhereToItemProps {
    title: string;
    openPage: () => void;
    icon: any;
    soon?: boolean;
    deliveryPrice?: number;
    freeDeliveryFrom?: number;
}

export function WhereToItem(props: WhereToItemProps) {
    const { title, openPage, icon, soon = false, deliveryPrice = 0, freeDeliveryFrom = 0 } = props;
    const { t } = useTranslation('payment');
    var priceUpToFreeDelivery = freeDeliveryFrom > 0 ? freeDeliveryFrom - cartWorth() : 0;

    return (
        <Button disabled={soon || (priceUpToFreeDelivery > 0 && !deliveryPrice)}
            sx={{
                color: "black",
                backgroundColor: "white",
                p: "0px",
                my: "10px",
                width: "100%",
                "&:hover": {
                    backgroundColor: "rgba(235, 235, 235)"
                }
            }}
            onClick={() => openPage()}>
            <Box boxShadow={5}
                sx={{
                    borderRadius: "10px",
                    width: "100%",
                }}>
                <Grid sx={{ position: "relative", width: "100%" }}>
                    <Box sx={{ filter: soon && "grayscale(1)" }}>{icon}</Box>
                    {soon && <Typography sx={{ position: "absolute", left: "0px", right: "0px", top: "30px", transform: "rotate(15deg)", fontWeight: 900, color: "paulini.orange", fontSize: "25px" }}>{t('soon')}</Typography>}
                </Grid>
                <Typography>{title}</Typography>
                {priceUpToFreeDelivery > 0 &&
                    <Box sx={{ backgroundColor: "paulini.orange", m: "5px", borderRadius: "15px", color: "white", textTransform: "none" }}>
                        <Typography> {t('freeDeliveryFrom', { price: freeDeliveryFrom.toPrice() })}</Typography>
                        <Typography>{t('priceUpToFreeDelivery', { price: priceUpToFreeDelivery.toPrice() })}</Typography>
                    </Box>
                }
                <ChevronRightIcon sx={{ color: "gray", fontSize: "60px", opacity: 0.3, position: "absolute", right: "0px", top: "50px" }} />
            </Box>
        </Button >
    );
}
