import { Grid, Divider, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { NavBar } from "../appBars/navBar/navBar"
import { ListStyled } from "./partnersStyles"
import { PartnerClient } from "../../helpers/client";
import { apiKey, appStyleMode, imagesUri } from "../../helpers/clientConfigs";
import { Partner } from "orderme-api-integration-client";
import { Linear } from "../progressBars/linear";
import { ImageLoader } from "../imgLoader/imgLoader";
import { useTranslation } from 'react-i18next';
import { AppStyle } from "../../helpers/appStyle";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

declare let window: any;

export function Partners() {
  const partnerClient = new PartnerClient();
  const [partners, setPartners] = useState<Partner[]>();
  const { t } = useTranslation('info');
  const isMobileApp = appStyleMode === AppStyle.Mobile;

  useEffect(() => {
    getPartners();
  }, [])

  const getPartners = () => {
    partnerClient.get().then(response => {
      setPartners(response);
      console.log("response", response);
    }).catch(err => console.log(err));
  }

  const openSite = (url: string) => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(url, '_system');
    }
    else {
      window.open(url, "_blank");
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('partners')} />
      {!partners && (<Linear />)}
      <BreadcrumbsBar to={"partners"} />
      {partners &&
        (<ListStyled isMobile={isMobileApp}>
          {partners.map((partner, index) => {
            return (
              <>
                <ListItemButton sx={{
                  ":hover": {
                    backgroundColor: "transparent"
                  }
                }} onClick={() => openSite(partner.webAddress)} key={index}>
                  <Grid container direction="column" justifyContent="center" alignItems="center"
                    sx={{
                      height: isMobileApp ? (window.innerHeight - 56) / 4 : "250px",
                      minHeight: "150px"
                    }}>
                    <ImageLoader
                      sx={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={`${imagesUri}/${partner.logoId}?orderme-api-key=${apiKey}&height=500`}
                      alt={partner.name} />
                  </Grid>
                </ListItemButton>
                <Divider />
              </>
            )
          })}
        </ListStyled>
        )}
    </Grid>
  );
}