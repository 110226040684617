import { Box, Link } from "@mui/material";
import { styled } from '@mui/system';

export const LinkStyled = styled(Link)(({ theme }) => ({
  marginTop: "10px",
  fontSize: "20px",
  color: theme.palette.paulini.blue
}));

export const LinkStyledWebsite = styled(Link)({
  marginTop: "10px",
  fontSize: "20px",
  color: "white",
  '&:hover': {
    cursor: "pointer",
  }
});

export const PhoneIconImg = styled('img')({
  height: "35px",
  marginRight: "20px",
  marginLeft: "10px"
});

export const LockIconImg = styled('img')({
  height: "35px",
  marginRight: "20px"
});

export const ErrorMessageBox = styled(Box)(({ theme }) => ({
  color: theme.palette.paulini.error,
  marginTop: "5px",
  textAlign: "center"
}));

export const LoginBoxStyleWebsite = {
  "button": {
    borderRadius: "15px",
    height: "35px",
    cursor: "pointer",
    paddingLeft: "40px"
  }
} as const;

export const LoginBoxStyleMobile = {
  "button": {
    borderRadius: "10px",
    height: "45px",
    cursor: "pointer",
    paddingLeft: "40px",
    marginBottom: "10px"
  },
  "button::before": {
    backgroundPositionY: "11px"
  }
} as const;