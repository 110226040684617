import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { ChangePasswordRequest } from "orderme-api-integration-client";
import { DialogProps } from "../../../interfaces/interfaces"
import { CustomerClient } from "../../../helpers/client";
import { InputField } from "../../inputField/inputField";
import { Popup } from "../../popup/popup";
import CreateIcon from '@mui/icons-material/Create';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "../../../redux/hooks";
import { forceLoginAgain } from "../../../redux/reducers/authReducer";
import { confirmPhone as makePhoneConfirmed } from "../../../redux/reducers/customerReducer";

interface FieldValues {
  phoneCode: string
}

const initValue = {
  phoneCode: ""
} as FieldValues;

declare const window: any;

export function ConfirmPhone(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, open } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const customersClient: CustomerClient = new CustomerClient();
  const [textFieldValues, setTextFieldValues] = useState<FieldValues>(initValue);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const { t } = useTranslation('account');

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    onClose();
  };

  useEffect(() => {
    validate();
  }, [textFieldValues]);

  const validate = () => {
    Object.values(textFieldValues).every(value => value != "") ? setIsButtonDisabled(false) : setIsButtonDisabled(true);
  }

  const confirmPhone = () => {
    customersClient.confirmPhone(textFieldValues.phoneCode).then(response => {
      setSuccess(true);
      dispatch(makePhoneConfirmed());
      setTimeout(function () {
        dispatch(forceLoginAgain());
      }, 1500);

    }).catch(error => {
      console.log(error);
      setFailure(true);
    });
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    const fieldValue = { [name]: value } as Pick<ChangePasswordRequest, keyof ChangePasswordRequest>;

    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  return (
    <Popup onClose={() => handleClose()} open={open} dialogTitle={t('confirmPhone')} dialogContent={
      <InputField inputValue={textFieldValues.phoneCode} fieldName="phoneCode" placeholder={t('confirmationPhoneCode')} handleFieldChange={handleFieldChange} icon={<CreateIcon />} />
    }
      dialogActions={
        <Button disabled={isButtonDisabled} onClick={() => confirmPhone()} variant="contained" sx={{ color: "white", backgroundColor: "paulini.orange", width: "100%" }} >{t('confirm')}</Button>
      }
      isSuccess={success}
      successText={t('successConfirmation')}
      isFailure={failure} />
  );
}