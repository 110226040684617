import { ServiceItem } from "orderme-api-integration-client";
import { LanguageItem } from "../interfaces/interfaces";

const customerNumberKey: string = "customerNumber";
const cartServicesKey: string = "cartServices";
const lastPhoneConfirmTimeKey: string = "lastPhoneConfirmTime";
const lastEmailConfirmTimeKey: string = "lastEmailConfirm";
const languageKey: string = "language";
const cookiesAccepted: string = "cookies-consent"
const analyticCookiesAccepted: string = "cookies-consent-analytics"
const urlBeforeLogin: string = "urlBeforeLogin"

export const setCustomerNumber = (customerNo: number) => {
  localStorage.setItem(customerNumberKey, customerNo.toString());
}

export const getCustomerNumber = () => {
  var customerNoString = localStorage.getItem(customerNumberKey);
  return customerNoString ? +customerNoString : 0;
}

export const getCartServiceIds = () => {
  var serviceIdsString = localStorage.getItem(cartServicesKey);
  if (serviceIdsString)
    return serviceIdsString.split(",");

  return [];
}

export const clearServiceIds = () => {
  localStorage.removeItem(cartServicesKey);
}

export const updateCartServicesIds = (services: ServiceItem[]) => {
  if (services?.length > 0)
    localStorage.setItem(cartServicesKey, services.map(s => s.id).join(","));
  else
    clearServiceIds();
}

export const setLastPhoneConfirmTime = (time: string) => {
  localStorage.setItem(lastPhoneConfirmTimeKey, time);
}

export const getLastPhoneConfirmTime = () => {
  return localStorage.getItem(lastPhoneConfirmTimeKey);
}

export const removeLastPhoneConfirm = () => {
  localStorage.removeItem(lastPhoneConfirmTimeKey);
}

export const setLastEmailConfirm = (time: string) => {
  localStorage.setItem(lastEmailConfirmTimeKey, time);
}

export const getLastEmailConfirm = () => {
  return localStorage.getItem(lastEmailConfirmTimeKey);
}

export const removeLastEmailConfirm = () => {
  return localStorage.removeItem(lastEmailConfirmTimeKey);
}

export const setLanguage = (lang: LanguageItem) => {
  localStorage.setItem(languageKey, lang.key);
}

export const getLanguage = () => {
  return localStorage.getItem(languageKey);
}

export const isCookiesAccepted = () => {
  return localStorage.getItem(cookiesAccepted) === "true" &&
    localStorage.getItem(analyticCookiesAccepted);
}

export const isAnalyticCookiesAccepted = () => {
  return localStorage.getItem(analyticCookiesAccepted) === "true";
}

export const acceptCookies = (analyticsAccepted: boolean) => {
  localStorage.setItem(cookiesAccepted, "true");
  localStorage.setItem(analyticCookiesAccepted, analyticsAccepted.toString());
}

export const setUrlBeforeLogin = (url: string) => {
  localStorage.setItem(urlBeforeLogin, url);
}

export const getUrlBeforeLogin = () => {
  return localStorage.getItem(urlBeforeLogin);
}

export const removeUrlBeforeLogin = () => {
  localStorage.removeItem(urlBeforeLogin);
}