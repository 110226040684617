import { Grid, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavBar } from "../appBars/navBar/navBar"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { openRules } from "../policy/rulesDownloader"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PrizeIcon from '../../logo/prize.png'
import DealIcon from '../../logo/deal.png'
import { AccountItem } from "../account/accountItem";
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

declare let window: any;

export function Info() {
    const navigate = useNavigate();

    const { t } = useTranslation('info');
    const { t: tSalesPoint } = useTranslation('salesPoint');
    const { t: tAchievement } = useTranslation('achievement');

    return (
        <Grid>
            <NavBar barTitle={t('information')} />
            <Grid>
                <Box className={appStyleMode === AppStyle.Mobile && "info-items-list"}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <AccountItem icon={<AddCircleOutlineIcon />} title={t('allServices')} open={() => navigate("/search")} />
                        <AccountItem icon={<LocationOnIcon />} title={tSalesPoint('salesPoints')} open={() => navigate('/point/All')} />
                        <AccountItem
                            icon={<img height={25} src={PrizeIcon} />}
                            title={tAchievement('achievements')}
                            open={() => navigate("/achievements")} />
                        <AccountItem
                            icon={<img height={25} src={DealIcon} />}
                            title={t('partners')}
                            open={() => navigate("/partners")} />
                        <AccountItem icon={<AssignmentIcon />} title={t('rules')} open={() => openRules()} />
                        <AccountItem icon={<HelpIcon />} title={t('contactUs')} open={() => navigate("/support")} />
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}