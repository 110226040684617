import { useEffect, useState } from "react";
import { Grid, IconButton, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { LoginDialog } from "../auth/login/loginDialog"
import { RootState } from "../../redux/store";
import HistoryIcon from '@mui/icons-material/History';
import { NavBar } from "../appBars/navBar/navBar"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { CustomerInfo } from "orderme-api-integration-client";
import { IconImage } from "./accountStyles"
import { PasswordChange } from "../auth/accountSettings/passwordChange"
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { LogoutConfirmation } from "../auth/logout/logoutConfirmation"
import { AccountItem } from "./accountItem"
import LoginIcon from '../../logo/login.png'
import BookIcon from "../../logo/book.png"
import CoinIcon from "../../logo/coin.png"
import { roundDoubleNumbers } from "../../helpers/currency";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { CartBarBadgeIcon } from "../shoppingCart/cartBarBadgeIcon";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { LanguageMenu } from "../appBars/homeBar/languageMenu";

export function Account() {
  const navigate = useNavigate();
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const isPasswordRestored: boolean = useAppSelector((state: RootState) => state.authState.isPasswordRestored);
  const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
  const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const { t } = useTranslation('account');

  useEffect(() => {
    if (isUserLoggedIn) {
      assignCustomerDetails();
      isPasswordRestored && showPasswordChange();
    }
  }, [isPasswordRestored, isUserLoggedIn]);

  const logout = () => {
    setOpenLogoutConfirmation(true);
  }

  const showLogin = () => {
    setOpenLogin(true);
  }

  const showPasswordChange = () => {
    setOpenPasswordChange(true);
  }

  const closePasswordChange = () => {
    setOpenPasswordChange(false);
  }

  const closeLogin = () => {
    setOpenLogin(false);
  }

  const closeLogoutConfirmation = () => {
    setOpenLogoutConfirmation(false);
  }

  return (
    <Grid>
      <NavBar
        barTitle={isUserLoggedIn ? `${customerInfo.name} ${roundDoubleNumbers(customerInfo.credit)} €` : t('account')}
        actions={isUserLoggedIn ?
          (<Box>
            <IconButton onClick={() => navigate('/credits')} sx={{ marginRight: "-20px", color: 'white' }}>
              <AddCircleOutlineIcon sx={{ height: "35px", width: "35px" }} />
            </IconButton>
            <LanguageMenu />
          </Box>)
          : <LanguageMenu />} />
      <Grid>
        <Box className={appStyleMode === AppStyle.Mobile && "account-items-list"}>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <AccountItem
              isVisible={!isUserLoggedIn}
              icon={<IconImage src={LoginIcon} />}
              title={t('login')} open={() => showLogin()} />
            <AccountItem title={t('yourCart')} open={() => navigate("/cart")}
              icon={<CartBarBadgeIcon />} />
            <AccountItem
              isBold={isPasswordRestored}
              isVisible={isPasswordRestored && isUserLoggedIn}
              icon={<LockOpenIcon />}
              title={t('changePassword')}
              open={() => showPasswordChange()} />
            <AccountItem
              isDisabled={isPasswordRestored}
              isVisible={isUserLoggedIn}
              icon={<HistoryIcon />}
              title={t('ordersHistory')}
              open={() => navigate("/orderHistory")} />
            <AccountItem
              icon={<IconImage src={BookIcon} />}
              isDisabled={isPasswordRestored}
              isVisible={isUserLoggedIn}
              title={t('addressBook')}
              open={() => navigate("/addressBook", { state: false })} />
            <AccountItem
              isDisabled={isPasswordRestored}
              isVisible={isUserLoggedIn}
              icon={<AccountBoxIcon />}
              title={t('settings')}
              open={() => navigate("/accSettings")} />
            <AccountItem
              isVisible={isUserLoggedIn}
              isDisabled={isPasswordRestored}
              icon={<IconImage src={CoinIcon} />}
              title={t('creditsAndCoupons')}
              open={() => navigate("/credits")} />
            <AccountItem
              isVisible={isUserLoggedIn}
              icon={<ExitToAppIcon />}
              title={t('logout')}
              open={() => logout()} />
          </Grid>
        </Box>
      </Grid>

      <LoginDialog open={openLogin} onClose={closeLogin} />
      <PasswordChange open={openPasswordChange} onClose={closePasswordChange} />
      <LogoutConfirmation open={openLogoutConfirmation} onClose={closeLogoutConfirmation} />
    </Grid>
  );
}