import { AppBar, Grid, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const PauliniTextLogoImg = styled('img')({
  maxWidth: "30%"
});

export const PauliniLogoImg = styled('img')({
  marginLeft: "10px",
  width: "200px",
  cursor: "pointer",
  '@media (max-width: 400px)': {
    width: "150px",
    marginLeft: "0px"
  }
});

export const HomeItemsGrid = styled(Grid)({
  flexGrow: 1,
  textAlign: "right"
});

export const CartBartTypography = styled(Typography)({
  fontSize: "24px",
  display: "inline-block"
});

export const BottomAppBar = styled(AppBar)(({ theme }) => ({
  top: "auto",
  bottom: 0,
  backgroundColor: theme.palette.paulini.orange,
  height: "61px",
  width: "100%"
}));

export const SocialNetworkIconButton = styled(IconButton)({
  color: "white"
});