import { Badge } from "@mui/material";
import { RootState } from "../../redux/store";
import { ServiceItem } from "orderme-api-integration-client";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useAppSelector } from "../../redux/hooks";

export function CartBarBadgeIcon() {
    const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
    return (
        <Badge
            sx={{
                mr: "10px",
                "& .MuiBadge-badge": {
                    backgroundColor: "#f50057",
                    color: "white"
                }
            }}
            badgeContent={servicesInBasket.length}
        >
            <ShoppingCartIcon />
        </Badge>
    );
}