import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { NavBar } from "../appBars/navBar/navBar";
import StoreIcon from "../../logo/store.png"
import HomeIcon from "../../logo/home.png"
import ClosetIcon from "../../logo/closet.png"
import BusinessLockerIcon from "../../logo/businessLocker.png"
import { WhereToItem } from "./whereToItem"
import { DeliveryClient, ServiceClient, SettingClient } from "../../helpers/client";
import { DeliveryOptions, DeliveryType, ServiceItem } from "orderme-api-integration-client";
import { ImageLoader } from "../imgLoader/imgLoader";
import { useTranslation } from 'react-i18next';
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { clearDelivery, updateDeliveryService, updateFreeDeliveryFrom } from "../../redux/reducers/pickupReducer";
import { useAppDispatch } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function WhereTo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deliveryClient: DeliveryClient = new DeliveryClient();
  const settingClient: SettingClient = new SettingClient();
  const serviceClient: ServiceClient = new ServiceClient();
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions[]>();
  const [deliveryServiceId, setDeliveryServiceId] = useState<string>();
  const [freeDeliverFrom, setFreeDeliverFrom] = useState<number>();
  const [deliveryService, setDeliveryService] = useState<ServiceItem>();
  const [deliveryPrice, setDeliveryPrice] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    getDeliverySettings();
    getDeliveryOptions();
    dispatch(clearDelivery());
  }, []);

  useEffect(() => {
    if (!deliveryServiceId)
      return;

    serviceClient.getServiceById(deliveryServiceId).then((response) => {
      setDeliveryService(response);
      setDeliveryPrice(response.price);
    }).catch((err) => console.log(err));
  }, [deliveryServiceId]);

  const getDeliveryOptions = () => {
    deliveryClient.get().then((response) => {
      setDeliveryOptions(response);
      console.log(response);
    }).catch((err) => console.log(err));
  }

  const getDeliverySettings = () => {
    settingClient.get().then((response) => {
      setFreeDeliverFrom(response.orders.freeDeliverFrom);
      setDeliveryServiceId(response.orders.deliveryServiceId);
    }).catch((err) => console.log(err));
  }

  const openPage = (type: DeliveryType) => {
    switch (type) {
      case DeliveryType.SalesPoint:
        navigate(`/point/${type}`, { state: true });
        break;
      case DeliveryType.Wardrobe:
        navigate(`/businessLocker`, { state: true });
        break;
      case DeliveryType.Address:
        dispatch(updateDeliveryService(deliveryService));
        dispatch(updateFreeDeliveryFrom(freeDeliverFrom));
        navigate('/addressBook', { state: true });
        break;
    }
  }

  const itemImage = (type: DeliveryType) => {
    switch (type) {
      case DeliveryType.SalesPoint:
        return StoreIcon;
      case DeliveryType.Wardrobe:
        return BusinessLockerIcon;
      case DeliveryType.Address:
        return HomeIcon;
    }
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t('payment:whereToDeliver')} />
      <BreadcrumbsBar to="cart/whereTo" />
      <Grid
        sx={{ maxWidth: "1000px" }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <Box sx={{ width: appStyleMode !== AppStyle.Mobile ? "90%" : null }} className={appStyleMode === AppStyle.Mobile && "where-to-list"}>
          {deliveryOptions?.map((option) => (
            <WhereToItem
              title={option.name}
              openPage={() => openPage(option.type)}
              deliveryPrice={option.type === DeliveryType.Address ? deliveryPrice : 0}
              freeDeliveryFrom={option.type === DeliveryType.Address ? freeDeliverFrom : 0}
              icon={<ImageLoader src={itemImage(option.type)} sx={{ height: "100px" }} alt={option.name} />} />
          ))}
          <WhereToItem
            title={t('salesPoint:smartLocker')}
            openPage={() => { }}
            icon={<ImageLoader src={ClosetIcon} sx={{ height: "100px" }} alt="SmartLocker" />}
            soon={true} />
        </Box>
      </Grid>
    </Grid >
  );
}
