import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { styled } from '@mui/system';

export const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: 10,
  backgroundColor: theme.palette.paulini.orange,
  height: 50,
  width: "100%",
  color: "white",
  "&:disabled": {
    backgroundColor: "#979797",
    color: "white"
  },
  "&:hover": {
    backgroundColor: theme.palette.paulini.orangeHover,
  }
}));

export const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  padding: "10px 15px",
  backgroundColor: theme.palette.paulini.blue,
  color: "white",
  overflowX: "hidden",
  borderRadius: "10px 10px 0 0"
}));

export const DialogContentRoot = styled(DialogContent)({
  padding: 1,
  backgroundColor: "white"
});

export const DialogActionRoot = styled(DialogActions)({
  padding: 0,
  backgroundColor: "white",
  borderRadius: "0 0 10px 10px",
  "& button": { borderRadius: "4px" }
});