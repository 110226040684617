import { useEffect, useState } from "react";
import { ChangePhoneRequest } from "orderme-api-integration-client";
import { DialogProps } from "../../../interfaces/interfaces"
import { CustomerClient } from "../../../helpers/client";
import { Popup } from "../../popup/popup";
import { InputField } from "../../inputField/inputField";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "../../../redux/hooks";
import { forceLoginAgain } from "../../../redux/reducers/authReducer";
import { isValidPhoneNumber } from "../../../helpers/validator";

export function MobileChange(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, open } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const customersClient: CustomerClient = new CustomerClient();
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState(null);
  const { t } = useTranslation('account');

  useEffect(() => {
    setIsButtonDisabled(!isValidPhoneNumber(phone));
    setStatus(null);
  }, [phone]);

  const change = async () => {
    setStatus(null);

    const request = new ChangePhoneRequest({ phone });
    try {
      await customersClient.changePhone(request);
      setStatus(true);

      setTimeout(() => {
        onClose();
        setStatus(null);
        dispatch(forceLoginAgain());
      }, 3000);
    }
    catch (err) {
      setStatus(false);
      console.log(err);
    }
  }

  const handleOnClose = () => {
    setStatus(null);
    setPhone(null);
    onClose();
  }

  return (
    <Popup onClose={() => handleOnClose()} open={open} dialogTitle={t('changePhone')} dialogContent={
      <InputField fieldName="phone" inputValue={phone} placeholder={t('newPhoneInput')} handleFieldChange={(e) => setPhone(e.target.value)} icon={<PhoneAndroidIcon />} />
    }
      onAction={() => change()}
      buttonContent={t('confirm')}
      isButtonDisabled={isButtonDisabled}
      isSuccess={status === true}
      successText={t('phoneChanged')}
      isFailure={status === false}
    />
  );
}