import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from "react-redux";
import { store } from "./redux/store"
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles'
import './i18n/i18n';
import AuthProvider from './auth/authProvider';
import { AppStyle } from './helpers/appStyle';
import { appStyleMode } from './helpers/clientConfigs';
import { filledInputClasses } from '@mui/material';
import PauliniContent from './components/content/pauliniContent';
import MobileContent from './components/content/mobileContent';

declare let window: any;

const themeMobile = createTheme({
  typography: {
    "fontFamily": `"Roboto", sans-serif`,
    "fontSize": 16
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#ff9102",
          width: "auto",
          boxShadow: "1px 1px 5px grey",
          paddingLeft: "25px",
          paddingRight: "25px",
          borderRadius: "15px",
          "&:disabled": {
            color: 'white',
            backgroundColor: "#9f9f9f",
            boxShadow: "1px 1px 5px grey"
          },
          ":hover": {
            backgroundColor: "#cc7400",
            boxShadow: "1px 1px 5px grey"
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "12px",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#0096d6"
    },
    secondary: {
      light: "#ff4081",
      main: "#f50057",
      dark: "#c51162",
      contrastText: "#fff"
    },
    paulini: {
      blue: "#0096d6",
      blueHover: "#0096d6",
      orange: "#ff9102",
      orangeHover: "#ff9102",
      green: "#17d133",
      greenHover: "#17d133",
      error: "#d32f2f"
    }
  }
});

const themePaulini = createTheme({
  typography: {
    "fontFamily": `"Roboto", sans-serif`,
    "fontSize": 16
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          backgroundColor: "#ff9102",
          width: "auto",
          boxShadow: "1px 1px 5px grey",
          paddingLeft: "25px",
          paddingRight: "25px",
          borderRadius: "15px",
          "&:disabled": {
            color: 'white',
            backgroundColor: "#9f9f9f",
            boxShadow: "1px 1px 5px grey"
          },
          ":hover": {
            backgroundColor: "#cc7400",
            boxShadow: "1px 1px 5px grey"
          }
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "12px"
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "white",
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "white"
          }
        },
        input: {
          ":autofill": {
            borderRadius: "inherit"
          },
        }
      },
    }
  },
  palette: {
    background: {
      default: "#0096d6"
    },
    paulini: {
      blue: "#0096d6",
      blueHover: "#007db3",
      orange: "#ff9102",
      orangeHover: "#cc7400",
      green: "#17d133",
      greenHover: "#12a127",
      error: "#d32f2f"
    }
  }
});

const startApp = () => {
  const container = document.getElementById('root');
  if (appStyleMode === AppStyle.Mobile) {
    document.body.classList.add('mobile-body');
    container.classList.add('mobile-root');
  }
  else if (appStyleMode === AppStyle.Paulini) {
    container.classList.add('paulini-root');
  }

  const root = createRoot(container);
  root.render(appContent());
}

const appContent = () => {
  return (
    <ThemeProvider theme={appStyleMode === AppStyle.Paulini ? themePaulini : themeMobile}>
      <Provider store={store}>
        <AuthProvider>
          {appStyleMode === AppStyle.Paulini ? <PauliniContent /> : <MobileContent />}
        </AuthProvider>
      </Provider>
    </ThemeProvider>
  );
}

if (!window.cordova) {
  startApp()
}
else {
  document.addEventListener('deviceready', startApp, false);
}