import { useEffect, useState } from "react";
import { Grid, InputAdornment, IconButton, Typography, Box } from "@mui/material";
import { ChangePasswordRequest, CustomerLoginInfo } from "orderme-api-integration-client";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DecodedToken, DialogProps } from "../../../interfaces/interfaces"
import { CustomerClient } from "../../../helpers/client";
import { Popup } from "../../popup/popup";
import { InputField } from "../../inputField/inputField";
import CreateIcon from '@mui/icons-material/Create';
import { useTranslation } from 'react-i18next';
import { PasswordRulesGrid } from "./accountSettingsStyles";
import { authenticate, forceLoginAgain } from "../../../redux/reducers/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { jwtDecode } from "jwt-decode";

interface FieldValues {
  currentPassword: string;
  newPassword: string;
  retypedPassword: string
}

const initValue = {
  currentPassword: "",
  newPassword: "",
  retypedPassword: ""
} as FieldValues;

export function PasswordChange(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { onClose, open } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const customersClient: CustomerClient = new CustomerClient();
  const [textFieldValues, setTextFieldValues] = useState<FieldValues>(initValue);
  const [showCurrentPsw, setShowCurrentPsw] = useState(false);
  const [showNewPsw, setShowNewPsw] = useState(false);
  const [showRetypedPsw, setShowRetypedPsw] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState<string>(null);
  const passwordMatch = textFieldValues.newPassword === textFieldValues.retypedPassword;
  const { t } = useTranslation('account');
  const loginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);
  const accessToken: string = useAppSelector((state: RootState) => state.authState.accessToken);

  useEffect(() => {
    validate();
  }, [textFieldValues]);

  const validate = () => {
    Object.values(textFieldValues).every(value => value !== "") && passwordMatch ? setIsButtonDisabled(false) : setIsButtonDisabled(true);
  }

  const change = () => {
    const changePasswordRequest = {
      currentPassword: textFieldValues.currentPassword,
      newPassword: textFieldValues.newPassword
    } as ChangePasswordRequest;
    setSuccess(false);
    setFailure(null);

    customersClient.changePassword(changePasswordRequest).then(() => {
      setSuccess(true);
      closeAndLogout();
    })
      .catch((err) => {
        console.log(err);
        setFailure(err.title);
      });
  }

  const closeAndLogout = () => {
    setIsButtonDisabled(true);
    setTimeout(function () {
      if (loginInfo.phone)
        dispatch(authenticate({ input: loginInfo.phone, password: textFieldValues.newPassword }));
      else if (accessToken) {
        var decoded = jwtDecode(accessToken) as DecodedToken;
        dispatch(authenticate({ input: decoded.nameid, password: textFieldValues.newPassword }));
      }
      else {
        dispatch(forceLoginAgain());
      }

      onClose();
      setIsButtonDisabled(false);
      setSuccess(false);
      setFailure(null);
    }, 2000);
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    const fieldValue = { [name]: value } as Pick<ChangePasswordRequest, keyof ChangePasswordRequest>;

    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  return (
    <Popup onClose={() => onClose()} open={open} dialogTitle={t('changePassword')} dialogContent={
      <>
        <InputField fieldName="currentPassword" placeholder={t('oldPassword')} handleFieldChange={handleFieldChange} icon={<CreateIcon />} isPassword={!showCurrentPsw}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowCurrentPsw(!showCurrentPsw)} edge="end">
                {showCurrentPsw ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>} />

        <InputField fieldName="newPassword" placeholder={t('newPassword')} handleFieldChange={handleFieldChange} icon={<CreateIcon />} isPassword={!showNewPsw}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowNewPsw(!showNewPsw)} edge="end">
                {showNewPsw ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>} />

        <InputField fieldName="retypedPassword" placeholder={t('retypeNewPassword')} handleFieldChange={handleFieldChange} icon={<CreateIcon />} isPassword={!showRetypedPsw}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowRetypedPsw(!showRetypedPsw)} edge="end">
                {showRetypedPsw ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>} />

        {(textFieldValues.retypedPassword !== "" && textFieldValues.newPassword !== "" && !passwordMatch) &&
          <Grid container justifyContent="center">
            <Box sx={{ color: "paulini.error", marginBottom: "10px" }}>{t('differentPasswords')}</Box>
          </Grid>}
        <PasswordRulesGrid>
          {(!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(textFieldValues.newPassword) || textFieldValues.newPassword.length < 8) && <Typography>{t('passwordRules')}</Typography>}
          {textFieldValues.newPassword.length < 8 && <Typography>* {t('passwordRule8Chars')}</Typography>}
          {!/[A-Z]+/.test(textFieldValues.newPassword) && <Typography>* {t('passwordRuleCapitalLetter')}</Typography>}
          {!/[a-z]+/.test(textFieldValues.newPassword) && <Typography>* {t('passwordRuleLowerCase')}</Typography>}
          {!/[0-9]+/.test(textFieldValues.newPassword) && <Typography>* {t('passwordRuleNumber')}</Typography>}
        </PasswordRulesGrid>
      </>
    }
      onAction={() => change()}
      buttonContent={t('confirm')}
      isButtonDisabled={isButtonDisabled}
      isSuccess={success}
      successText={t('passwordChanged')}
      isFailure={failure !== null}
      failureText={failure} />

  );
}