import { IconButton } from "@mui/material";
import { styled } from '@mui/system';

export const AddButton = styled(IconButton)(({ theme }) => ({
  right: theme.spacing(1),
  '&:hover': {
    backgroundColor: "transparent",
  }
}));

export const RemoveButton = styled(IconButton)(({ theme }) => ({
  left: theme.spacing(1),
  '&:hover': {
    backgroundColor: "transparent",
  }
}));