import { useState, useEffect } from "react";
import { CircularProgress, Collapse, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { ActionButton, DialogActionRoot, DialogContentRoot, DialogTitleStyled } from "./popupStyles";
import CloseIcon from "../../logo/close.png";
import CheckIcon from '@mui/icons-material/Check';
import XIcon from '@mui/icons-material/Close';
import { isPromise } from "../../helpers/promise";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

interface PopupProps {
  open: boolean;
  onClose: () => void;
  dialogTitle: string;
  dialogContent?: JSX.Element;
  dialogActions?: JSX.Element;
  closeButton?: boolean;
  isButtonDisabled?: boolean;
  onAction?: () => void;
  buttonContent?: string;
  isSuccess?: boolean;
  isFailure?: boolean;
  successText?: string;
  failureText?: string;
}

export function Popup(props: PopupProps) {
  var { open, onClose, dialogTitle, dialogContent, dialogActions, closeButton = true, isButtonDisabled,
    onAction, buttonContent, isSuccess = false, successText, isFailure = false, failureText } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSuccess || isFailure) {
      setIsLoading(false);
    }
  }, [isSuccess, isFailure])

  const onClick = async () => {
    try {
      setIsLoading(true);
      const response = onAction();

      if (isPromise(response)) {
        await response;
      }
      setIsLoading(false);
    }
    catch {
      setIsLoading(false);
    }
  }

  const setLoaderElement = () => {
    if (isLoading) {
      return <CircularProgress sx={{ height: "20px", width: "20px", mr: "5px", color: "paulini.blue" }} />;
    }
    else if (isSuccess) {
      return <CheckIcon sx={{ color: "paulini.green" }} />;
    }
    else if (isFailure) {
      return <XIcon sx={{ color: "paulini.error" }} />;
    }

    return <div></div>
  }

  const button = () => {
    return (
      <ActionButton disabled={isButtonDisabled || isLoading} variant="contained" onClick={() => onClick()}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            {setLoaderElement()}
            <Typography>{buttonContent}</Typography>
          </Grid>
        </Grid>
      </ActionButton>
    );
  }

  return (
    <>
      <Dialog
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: appStyleMode === AppStyle.Mobile ? 'transparent' : null,
              backdropFilter: "blur(2px)"
            }
          }
        }}
        fullWidth
        PaperProps={{ sx: { margin: "40px", position: "relative", overflowY: "inherit", backgroundColor: "transparent" } }}
        onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
        open={open}>
        <DialogTitleStyled>
          <Grid container direction="row" alignItems="center">
            <Typography sx={{
              fontSize: '20px',
              textAlign: 'center',
              textTransform: "uppercase",
              padding: '8px',
              mx: 'auto'
            }}>{dialogTitle}</Typography>
            {closeButton && (<IconButton sx={{ color: "paulini.error", position: "absolute", right: "-25px", top: "-25px" }} onClick={() => onClose()}>
              <img src={CloseIcon} alt="" height="30px" width="30px" />
            </IconButton>)}
          </Grid>
        </DialogTitleStyled>
        <DialogContentRoot>
          {dialogContent}
          <Collapse in={isSuccess && !!successText}>
            <Typography sx={{ textAlign: "center", color: "paulini.green", mt: "5px" }}>{successText}</Typography>
          </Collapse>
          <Collapse in={isFailure && !!failureText}>
            <Typography sx={{ textAlign: "center", color: "paulini.error", mt: "5px" }}>{failureText}</Typography>
          </Collapse>
        </DialogContentRoot>
        <DialogActionRoot>
          {dialogActions ? dialogActions : button()}
        </DialogActionRoot>
      </Dialog>
    </>
  );
}