import { useEffect, useState } from "react";
import { Popup } from "../popup/popup";
import { DialogProps } from "../../interfaces/interfaces"
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { PriceByAmount } from "orderme-api-integration-client";

export interface ProductPricesDialogProps extends DialogProps {
    prices: PriceByAmount[];
    serviceName: string;
}

export function ProductPrices(props: ProductPricesDialogProps) {
    const { onClose, open, serviceName } = props;
    const { t } = useTranslation('services');
    const [prices, setPrices] = useState<PriceByAmount[]>();

    useEffect(() => {
        setPrices([...props.prices].sort((a, b) => a.amount - b.amount));
    }, [props.prices]);

    const getAmount = (price: PriceByAmount, index: number) => {
        const amountText = index + 1 < prices.length ?
            `${price.amount} - ${prices[index + 1].amount - 1} ` : `${price.amount}+ `;

        return amountText + t('pieces');
    }

    return (
        <Popup
            onClose={onClose}
            open={open}
            dialogTitle={serviceName}
            dialogContent={
                <Grid>
                    <Typography align="center">{t('bulkDiscountDescription')}</Typography>
                    {prices &&
                        <List sx={{ mx: "20px" }}>
                            {prices.map((price, index) => (
                                <ListItem key={index}
                                    sx={{
                                        backgroundColor: "paulini.blue",
                                        color: "white",
                                        my: "10px",
                                        borderRadius: "10px"
                                    }}>
                                    <ListItemText sx={{ textAlign: 'left' }} primary={getAmount(price, index)} />
                                    <ListItemText sx={{ textAlign: 'right' }} primary={price.price.toPrice()} />
                                </ListItem>
                            ))}
                        </List>
                    }
                </Grid>
            }
            dialogActions={<></>} />
    );
}