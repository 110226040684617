import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import lt from "./translations/lt.json";
import en from "./translations/en.json";
import ru from "./translations/ru.json";
import { getLanguage } from "../helpers/localStorageService";
import { defaultLanguage } from "./language";

export const resources = { lt, en, ru }

i18next.use(initReactI18next)
    .init({
        resources,
        lng: getLanguage() ?? defaultLanguage.key,
        fallbackLng: defaultLanguage.key
    });

export default i18next;