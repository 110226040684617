import CardMedia from '@mui/material/CardMedia';
import { Card, Link } from '@mui/material';
import bannerLogo from "../../logo/banner.gif"
import bannerLogoSmall from "../../logo/bannerSmall.gif"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export interface BannerProps {
    image: string;
    smallImage: string;
    url: string;
}

export function Banner() {
    const [bannerProps, setBannerProps] = useState<BannerProps>();
    const navigate = useNavigate();

    useEffect(() => {
        setBannerProps(getBanner());
    }, [])

    const getBanner = () => {
        return {
            image: bannerLogo,
            smallImage: bannerLogoSmall,
            url: "/news/8ea39346-1401-46a7-bd42-ecce293fc801"
        } as BannerProps
    }

    return (
        bannerProps &&
        <Card sx={{ mb: "10px", mx: "5px" }}>
            <Link
                sx={{
                    '&:hover': {
                        cursor: "pointer",
                    }
                }}
                onClick={() => navigate(bannerProps.url)}>
                <CardMedia
                    sx={{ display: { md: 'block', xs: 'none' }, minHeight: "340px" }}
                    component="img"
                    alt="Banner image"
                    image={bannerProps.image}
                />
                <CardMedia
                    sx={{ display: { md: 'none', xs: 'block' }, minHeight: "340px" }}
                    component="img"
                    alt="Banner small image"
                    image={bannerProps.smallImage}
                />
            </Link>
        </Card >
    );
};