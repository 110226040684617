import { ServiceItem } from "orderme-api-integration-client";
import { store } from "../redux/store";

const packingFee: number = 0;

export const originalCartWorth = () => {
    var servicesInBasket = store.getState().cartState.servicesInBasket;
    if (servicesInBasket.length === 0) {
        return 0;
    }

    var sum = servicesInBasket.map(a => a.originalPrice).reduce((a, b) => { return a + b; });
    return Math.round(sum * 100) / 100
}

export const cartWorth = () => {
    const isLoggedIn = store.getState().authState.isLoggedIn;
    var servicesInBasket = store.getState().cartState.servicesInBasket;
    if (servicesInBasket.length === 0) {
        return 0;
    }

    var sum = servicesInBasket
        .map(a => getServicePrice(a, servicesInBasket.filter(b => b.id === a.id).length, !isLoggedIn))
        .reduce((a, b) => { return a + b; });
    return Math.round(sum * 100) / 100
}

export const getFriendsCartWorth = () => {
    var servicesInBasket = store.getState().cartState.servicesInBasket;
    if (servicesInBasket.length === 0) {
        return 0;
    }

    var sum = servicesInBasket
        .map(a => getServicePrice(a, servicesInBasket.filter(b => b.id === a.id).length))
        .reduce((a, b) => { return a + b; });
    return Math.round(sum * 100) / 100
}

export const getCredits = () => {
    var credit: number = store.getState().customerState.customerInfo.credit;
    if (!credit) {
        return 0;
    }
    return credit;
}

export const getServicePrice = (service: ServiceItem, amount: number, useOriginalPrice?: boolean) => {
    var price = useOriginalPrice ? service.originalPrice : service.price;
    if (amount <= 0)
        return price;

    if (service.prices?.length > 0) {
        var newPrice = service.prices.sort((a, b) => b.amount - a.amount).find(p => amount >= p.amount)?.price;
        if (newPrice < price)
            price = newPrice;
    }

    return price;
}

export const getFullPrice = (includeDelivery: boolean) => {
    var fullPrice = cartWorth();
    if (includeDelivery) {
        const deliveryService = store.getState().pickupState.deliveryService;
        const freeDeliveryFrom = store.getState().pickupState.freeDeliveryFrom;
        if (deliveryService?.price > 0 && (!freeDeliveryFrom || fullPrice < freeDeliveryFrom)) {
            fullPrice += deliveryService.price;
        }
    }

    return fullPrice + packingFee;
}

export const getCreditsUsed = (includeDelivery: boolean) => {
    const fullPrice = getFullPrice(includeDelivery);
    const credits = getCredits();
    return fullPrice > credits ? credits : fullPrice;
}

export const getFinalPrice = (includeDelivery: boolean) => {
    return getFullPrice(includeDelivery) - getCreditsUsed(includeDelivery);
}

export const savings = () => {
    return originalCartWorth() - cartWorth()
}

export const isFreeDelivery = () => {
    const deliveryService = store.getState().pickupState.deliveryService;
    const freeDeliveryFrom = store.getState().pickupState.freeDeliveryFrom;
    const orderDestination = store.getState().pickupState.orderDestination;

    if (!orderDestination.address || !deliveryService)
        return true;

    // If free delivery is not defined, all deliveries must be paid
    if (!freeDeliveryFrom)
        return false;

    return cartWorth() >= freeDeliveryFrom;
}