import { SxProps, Theme } from "@mui/system";
import { useState } from "react";
import { ImageComponent } from "./imgLoaderStyles";

interface ImgLoaderProps {
  src: string,
  sx?: SxProps<Theme>;
  alt: string
}

export function ImageLoader(props: ImgLoaderProps) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { src, sx, alt } = props;

  return (
    <ImageComponent
      sx={sx}
      src={src}
      alt={alt}
      imageLoaded={imageLoaded}
      onLoad={() => setImageLoaded(true)}
    />
  )
}