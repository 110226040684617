import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ServiceItem } from "orderme-api-integration-client";
import { clearServiceIds, updateCartServicesIds } from "../../helpers/localStorageService";

export interface CartState {
    servicesInBasket: ServiceItem[]
}

const initialState = {
    servicesInBasket: [] as ServiceItem[]
}

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<ServiceItem>) => {
            var service = action.payload;
            state.servicesInBasket.push(service);
            updateCartServicesIds(state.servicesInBasket);
        },
        removeFromCart: (state, action: PayloadAction<ServiceItem>) => {
            var service = action.payload;
            state.servicesInBasket = state.servicesInBasket.filter(s => s.id !== service.id);
            updateCartServicesIds(state.servicesInBasket);
        },
        removeByOneFromCart: (state, action: PayloadAction<ServiceItem>) => {
            var service = action.payload;
            var index = state.servicesInBasket.map(x => x.id).lastIndexOf(service.id);
            if (index > -1)
                state.servicesInBasket.splice(index, 1);
            updateCartServicesIds(state.servicesInBasket);
        },
        clearCart: () => {
            clearServiceIds();
            return initialState;
        }
    },
})

export const { addToCart, removeFromCart, removeByOneFromCart, clearCart } = cartSlice.actions
export default cartSlice.reducer