import { ClientPlatformType } from "orderme-api-integration-client";

declare let window: any;

interface AppPlatform {
    platform: ClientPlatformType;
    version?: string;
}

export const getVersionAndPlatform = async (): Promise<AppPlatform> => {
    if (window.cordova) {
        const version = await window.cordova.getAppVersion.getVersionNumber();
        const platform = window.cordova.platformId.toString().toLowerCase();
        const clientPlatform =
            platform === "android" ?
                ClientPlatformType.Android :
                platform === "ios" ?
                    ClientPlatformType.Apple : ClientPlatformType.Web;

        return { version, platform: clientPlatform };
    }

    return { version: null, platform: ClientPlatformType.Web };
};
