import { Box } from "@mui/material";
import { styled } from '@mui/system';
import { Input } from "../../controls";

export const BoxStyled = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.blue,
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 15,
    margin: 20,
    padding: "10px 20px 5px 20px"
}));

export const CouponInput = styled(Input)({
    marginLeft: 20,
    marginRight: 20,
    marginTop: 30,
    marginBottom: 5
});
