import { CircularProgress, Dialog } from "@mui/material";

export function Spinner(props: { isActive: boolean }) {
    const { isActive } = props;

    return (<Dialog
        BackdropProps={{
            sx: {
                backgroundColor: 'transparent',
                backdropFilter: "blur(2px)"
            }
        }}
        fullWidth
        PaperProps={{
            sx: {
                width: "auto",
                overflow: "hidden",
                background: "none",
                boxShadow: "none"
            }
        }}
        open={isActive}>
        <CircularProgress size="120px" sx={{ color: "#0096d6" }} />
    </Dialog>)
}