import { Grid, Typography } from "@mui/material";
import { AddServiceButton } from "./shoppingCartStyles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function CartServicesEmpty() {
  const navigate = useNavigate();
  const { t } = useTranslation('payment');
  const addServiceClick = () => {
    if (appStyleMode === AppStyle.Mobile)
      navigate("/home")
    else
      navigate("/search")
  }

  return (
    <Grid
      className={appStyleMode === AppStyle.Mobile && "shopping-cart-services-empty-list"}
      sx={{
        height: appStyleMode === AppStyle.Paulini ? "270px" : null,
        color: "#b5b5b5"
      }}
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <SentimentDissatisfiedIcon
        sx={{
          height: "50px",
          width: "50px"
        }} />
      <Typography sx={{ fontStyle: "italic", fontSize: "30px", fontWeight: "lighter" }}>{t('emptyCart')}</Typography>
      <AddServiceButton variant="contained" onClick={addServiceClick}>{t('add')}</AddServiceButton>
    </Grid>
  );
}