import { LoginDialog } from "../../components/auth/login/loginDialog"
import { BrowserRouter } from "react-router-dom"
import { RootState } from '../../redux/store';
import GetParameterPopups from '../../popups';
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { HomeBar } from "../appBars/homeBar/homeBar"
import { CategoriesAppBar } from "../../components/categories/categoriesAppBar"
import { SocialNetworkBar } from "../../components/appBars/socialNetworksBar/socialNetworkBar"
import MenuDrawer from "../../components/menu/menuDrawer"
import { CopyrightBar } from "../../components/appBars/copyrightBar/copyrightBar"
import { resetLoginRequired } from "../../redux/reducers/authReducer"
import { CookiesBar } from "../../components/cookies/cookiesBar"
import { PasswordChange } from "../../components/auth/accountSettings/passwordChange"
import { useEffect, useState } from "react"
import PauliniRoutes from "../routes/pauliniRoutes"
import { Box, Container } from "@mui/material";
import { env } from "../../env";
import ScrollToTop from "./scrollToTop";

function PauliniContent() {
    var dispatch = useAppDispatch();
    const isLoginRequired: boolean = useAppSelector((state: RootState) => state.authState.isLoginRequired);
    const isUserLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const isPasswordRestored = useAppSelector((state: RootState) => state.authState.isPasswordRestored);
    const baseUrl = env.PUBLIC_URL ?? '';
    const [openPasswordChange, setOpenPasswordChange] = useState(false);

    const closeLogin = () => {
        dispatch(resetLoginRequired());
    }

    useEffect(() => {
        let ogUrlMeta = document.querySelector("meta[property='og:url']");
        if (ogUrlMeta)
            ogUrlMeta.setAttribute("content", window.location.href);
    }, [])

    useEffect(() => {
        if (isPasswordRestored) {
            if (isUserLoggedIn)
                setOpenPasswordChange(true);
        }
        else {
            setOpenPasswordChange(false);
        }
    }, [isPasswordRestored, isUserLoggedIn]);

    const closePasswordChange = () => {
        setOpenPasswordChange(false);
    }

    return (
        <Box sx={{ backgroundColor: "paulini.blue", color: "white" }}>
            <Container disableGutters
                maxWidth="xl"
                sx={{ minWidth: "300px", px: "24px", '@media (max-width: 600px)': { px: "12px" } }}>
                <BrowserRouter basename={baseUrl}>
                    <ScrollToTop />
                    <MenuDrawer />
                    <HomeBar />
                    <Box sx={{
                        pt: "100px",
                        '@media (max-width: 700px)': {
                            pt: "150px"
                        },
                        '@media (min-width: 1400px)': {
                            pt: "145px"
                        }
                    }}>
                        <CategoriesAppBar />
                        <PauliniRoutes />
                        <SocialNetworkBar />
                        <CopyrightBar />
                    </Box>
                    <GetParameterPopups />
                    <LoginDialog open={isLoginRequired} onClose={closeLogin} />
                    <CookiesBar />
                    <PasswordChange open={openPasswordChange} onClose={closePasswordChange} />
                </BrowserRouter >
            </Container>
        </Box >
    )
}
export default PauliniContent;
