import axios from "axios";
import { AppleStateProps, AppleTokenProps, GoogleStateProps, GoogleTokenProps, RegistrationProps } from "../interfaces/interfaces";
import { jwtDecode } from "jwt-decode";
import { AppStyle } from "./appStyle";
import { appStyleMode } from "./clientConfigs";
import { ExternalAuthenticationRequest, ExternalAuthenticationType } from "orderme-api-integration-client";

const facebookStateKey = "facebook-oauth-state";
const googleStateKey = "google-oauth-state";
const googleNonceKey = "google-oauth-nonce";
const appleStateKey = "apple-oauth-state";
const appleNonceKey = "apple-oauth-nonce";

export type FacebookProfileResponse = {
    first_name: string;
    last_name: string;
    email: string;
};

export function randomState(): string {
    const array = new Uint32Array(10);
    window.crypto.getRandomValues(array);
    const state = Array.from(array).map(num => num.toString(36)).join('');
    return addPrefix(state);
}

export function randomNonce(length = 32) {
    let nonce = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const values = window.crypto.getRandomValues(new Uint8Array(length));

    for (let i = 0; i < length; i++) {
        nonce += characters[values[i] % characters.length];
    }
    return addPrefix(nonce);
}

export function saveFacebookState(state: string) {
    if (appStyleMode === AppStyle.Paulini)
        localStorage.setItem(facebookStateKey, state);
    else
        sessionStorage.setItem(facebookStateKey, state);
}

export function getFacebookState() {
    if (appStyleMode === AppStyle.Paulini)
        return localStorage.getItem(facebookStateKey);

    return sessionStorage.getItem(facebookStateKey);
}

export function removeFacebookState() {
    if (appStyleMode === AppStyle.Paulini)
        localStorage.removeItem(facebookStateKey);
    else
        sessionStorage.removeItem(facebookStateKey);
}

export function saveGoogleState(state: string, nonce: string) {
    if (appStyleMode === AppStyle.Paulini) {
        localStorage.setItem(googleStateKey, state);
        localStorage.setItem(googleNonceKey, nonce);
    }
    else {
        sessionStorage.setItem(googleStateKey, state);
        sessionStorage.setItem(googleNonceKey, nonce);
    }
}

export function getGoogleState() {
    if (appStyleMode === AppStyle.Paulini) {
        return {
            state: localStorage.getItem(googleStateKey),
            nonce: localStorage.getItem(googleNonceKey)
        } as GoogleStateProps;
    }

    return {
        state: sessionStorage.getItem(googleStateKey),
        nonce: sessionStorage.getItem(googleNonceKey)
    } as GoogleStateProps;
}

export function removeGoogleState() {
    if (appStyleMode === AppStyle.Paulini) {
        localStorage.removeItem(googleStateKey);
        localStorage.removeItem(googleNonceKey);
    }
    else {
        sessionStorage.removeItem(googleStateKey);
        sessionStorage.removeItem(googleNonceKey);
    }
}

export function saveAppleState(state: string, nonce: string) {
    if (appStyleMode === AppStyle.Paulini) {
        localStorage.setItem(appleStateKey, state);
        localStorage.setItem(appleNonceKey, nonce);
    }
    else {
        sessionStorage.setItem(appleStateKey, state);
        sessionStorage.setItem(appleNonceKey, nonce);
    }
}

export function getAppleState() {
    if (appStyleMode === AppStyle.Paulini) {
        return {
            state: localStorage.getItem(appleStateKey),
            nonce: localStorage.getItem(appleNonceKey)
        } as AppleStateProps;
    }

    return {
        state: sessionStorage.getItem(appleStateKey),
        nonce: sessionStorage.getItem(appleNonceKey)
    } as AppleStateProps;
}

export function removeAppleState() {
    if (appStyleMode === AppStyle.Paulini) {
        localStorage.removeItem(appleStateKey);
        localStorage.removeItem(appleNonceKey);
    }
    else {
        sessionStorage.removeItem(appleStateKey);
        sessionStorage.removeItem(appleNonceKey);
    }
}

export function removeAllStates() {
    removeFacebookState();
    removeGoogleState();
}

export async function getFacebookRegistrationProps(accessToken: string, state: string) {
    const { data: res } = await axios.get<FacebookProfileResponse>("https://graph.facebook.com/v17.0/me?", {
        params: {
            access_token: accessToken,
            fields: "email,first_name,last_name",
            format: "json"
        }
    });

    return {
        externalLoginType: ExternalAuthenticationType.Facebook,
        externalAuthRequest: { accessToken: accessToken, state: state } as ExternalAuthenticationRequest,
        firstName: res.first_name,
        lastName: res.last_name,
        emailAddress: res.email
    } as RegistrationProps;
}

export function getGoogleTokenProps(accessToken: string) {
    var tokenProps: GoogleTokenProps = accessToken && jwtDecode(accessToken);
    return tokenProps;
}

export function getGoogleRegistrationProps(accessToken: string, state: string, code: string,) {
    var tokenProps = getGoogleTokenProps(accessToken);

    return {
        externalLoginType: ExternalAuthenticationType.Google,
        externalAuthRequest: { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest,
        firstName: tokenProps.given_name,
        lastName: tokenProps.family_name,
        emailAddress: tokenProps.email
    } as RegistrationProps;
}

export function getAppleRegistrationProps(accessToken: string, state: string, code: string, firstName?: string, lastName?: string) {
    var tokenProps = getAppleTokenProps(accessToken);

    return {
        externalLoginType: ExternalAuthenticationType.Apple,
        externalAuthRequest: { accessToken: accessToken, state: state, code: code } as ExternalAuthenticationRequest,
        firstName: firstName,
        lastName: lastName,
        emailAddress: tokenProps.email
    } as RegistrationProps;
}

export function getAppleTokenProps(accessToken: string) {
    var tokenProps: AppleTokenProps = accessToken && jwtDecode(accessToken);
    return tokenProps;
}

function addPrefix(input: string) {
    switch (appStyleMode) {
        case AppStyle.Paulini:
            return "paulini" + input;
        case AppStyle.Mobile:
            return "mobile" + input;
        default:
            return input;
    }
}