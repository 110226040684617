import { Grid } from "@mui/material";
import { GreenButton } from "./buttonStyles"

interface Props {
    content: string;
    onClick: () => void;
    isDisabled?: boolean;
    className?: string;
}

export function Button(props: Props) {
    const { content, isDisabled = false, onClick, className = '' } = props;

    return (
        <Grid container justifyContent="center" >
            <GreenButton onClick={onClick} className={`${className}`} disabled={isDisabled}
                variant="contained">{content}</GreenButton>
        </Grid>);
}