import { Navigate } from "react-router";
import { CustomerLoginInfo } from "orderme-api-integration-client";
import { RootState } from "./redux/store";
import usePrepareLink from "./popups/hooks/usePrepareLink";
import { GET_ENUMS } from "./popups/router";
import { useAppSelector } from "./redux/hooks";
import { RouterParam } from "./helpers/routerParam";

export type AuthRouteProps = {
    type: "guest" | "authenticated" | "onlyNotAuthenticated";
    approved?: boolean;
    children: JSX.Element;
};

function AuthRoute(props: AuthRouteProps) {
    const loginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);
    const isPasswordRestored: boolean = useAppSelector((state: RootState) => state.authState.isPasswordRestored);

    const confirmPhoneLink = usePrepareLink({
        query: {
            [RouterParam.Popup]: GET_ENUMS.popup.confirmPhone
        }
    });
    const confirmEmailLink = usePrepareLink({
        query: {
            [RouterParam.Popup]: GET_ENUMS.popup.confirmEmail
        }
    });

    const { type, approved } = props;
    const isAuthenticated = loginInfo?.customers?.length;

    if ((type === "authenticated" && (!isAuthenticated || isPasswordRestored)) ||
        (type === "onlyNotAuthenticated" && isAuthenticated)) {
        return <Navigate to="/home" replace />
    }

    if (isAuthenticated && approved && (!loginInfo.emailConfirmed || !loginInfo.phoneConfirmed)) {
        const confirmLink = !loginInfo.phoneConfirmed ? confirmPhoneLink : confirmEmailLink;
        return <Navigate to={{ pathname: "/home", search: confirmLink.search }} replace />
    }

    return props.children;
};

export default AuthRoute;