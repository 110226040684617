import { Popup } from "../popup/popup";
import { DialogProps } from "../../interfaces/interfaces"
import { Avatar, List, ListItem, ListItemText } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { getStatusColor, getStatusTranslation } from "../../helpers/orderStatus";
import { OrderStatus } from "orderme-api-integration-client";

function LegendListItem(props: { text: string, color: string }) {
    return (
        <ListItem>
            <Avatar sx={{ width: "20px", height: "20px", backgroundColor: props.color }}>&nbsp;</Avatar>
            <ListItemText sx={{ textAlign: 'right' }} primary={props.text} />
        </ListItem>
    );
}

export function OrderStatusLegend(props: DialogProps) {
    const { onClose, open } = props;
    const { t } = useTranslation('orderStatus');
    const { t: tOrder } = useTranslation('order');

    return (
        <>
            <Popup onClose={onClose} open={open} dialogTitle={tOrder('statuses')} dialogContent={
                <>
                    <List dense={true}>
                        <LegendListItem text={t('cancelledOrNotPaid')} color={getStatusColor(OrderStatus.Cancelled)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.Processing)} color={getStatusColor(OrderStatus.Processing)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.AcceptedBySalesPoint)} color={getStatusColor(OrderStatus.AcceptedBySalesPoint)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.Accepted)} color={getStatusColor(OrderStatus.Accepted)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.PickedUpByCourier)} color={getStatusColor(OrderStatus.PickedUpByCourier)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.Ready)} color={getStatusColor(OrderStatus.Ready)}></LegendListItem>
                        <LegendListItem text={getStatusTranslation(OrderStatus.Completed)} color={getStatusColor(OrderStatus.Completed)}></LegendListItem>
                    </List>
                </>
            }
                dialogActions={<></>} />
        </>
    );
}