import { List, Typography, svgIconClasses } from "@mui/material";
import { styled } from '@mui/system';

export const ErrorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.paulini.error,
  marginTop: "5px",
  textAlign: "center"
}));

export const InputsListStyled = styled(List)({
  [`& .${svgIconClasses.root}`]: {
    height: "35px",
    width: "35px"
  },
});