import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ClientPlatformType, CompatibilityClient } from "orderme-api-integration-client";
import { createCompatibilityClient } from "../../helpers/client";
import { getVersionAndPlatform } from "../../helpers/appPlatform";

declare let window: any;

export const verifyAppVersion = createAsyncThunk(
    'appLoader/verifyAppVersion',
    async (_: void, thunkAPI) => {
        var state = thunkAPI.getState() as RootState;
        if (!state.appLoaderState.isLatestAppVersion)
            return false;

        if (window.cordova) {
            const appPlatform = await getVersionAndPlatform();
            var client = createCompatibilityClient();
            return await client.getIsLatestClientApp(appPlatform.platform, appPlatform.version);
        }

        return true;
    },
)

interface AppLoaderState {
    isDisconnected: boolean;
    isLatestAppVersion: boolean;
    isLoaded: boolean;
}

const initialState = {
    isDisconnected: false,
    isLatestAppVersion: true,
    isLoaded: false
} as AppLoaderState;

const appLoaderSlice = createSlice({
    name: 'appLoader',
    initialState: initialState,
    reducers: {
        setDisconnected: (state, action: PayloadAction<boolean>) => {
            state.isDisconnected = action.payload;
        },
        finishLoading: (state) => {
            state.isLoaded = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(verifyAppVersion.fulfilled, (state, action) => {
            state.isLatestAppVersion = action.payload;
        })
    },
})

export const { setDisconnected, finishLoading } = appLoaderSlice.actions
export default appLoaderSlice.reducer