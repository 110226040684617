import { Toolbar, AppBar, Grid, IconButton, Typography, } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { BarProps } from "../../../interfaces/interfaces"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";

export function NavBar(props: BarProps) {
  const navigate = useNavigate();
  const { barTitle, open = () => { navigate(-1) }, actions } = props;

  const getContent = () => {
    if (appStyleMode === AppStyle.Mobile)
      return getMobileContent();

    return null;
  }

  const getMobileContent = () => {
    return (
      <Grid>
        <AppBar sx={{ backgroundColor: "paulini.blue" }} position="fixed" elevation={0}>
          <Grid sx={{ paddingRight: "20px" }} height="56px" container justifyContent="space-between" alignItems="center">
            <IconButton sx={{ marginLeft: "20px" }} onClick={() => open()}>
              <ArrowBackIosIcon sx={{ position: 'absolute', color: "white" }} />
            </IconButton>
            <Typography sx={{ marginLeft: 'auto', fontSize: "20px" }} variant="button" display="block"> {barTitle} </Typography>
            {actions}
          </Grid>
        </AppBar>
        <Toolbar />
      </Grid>
    );
  }

  return getContent();
}