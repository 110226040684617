import i18next from '../i18n/i18n'

export const getStatusError = (status: number) => {
  var errorMessage = "";
  switch (status) {
    case 400:
      errorMessage = i18next.t('error400', { ns: 'errors' });
      break;
    /* case 401:
      errorMessage = "Neautorizuota, turite prisijungti.";
      break;  */
    case 408:
      errorMessage = i18next.t('error408', { ns: 'errors' });
      break;
    case 500:
      errorMessage = i18next.t('error500', { ns: 'errors' });
      break;
    default:
      errorMessage = i18next.t('default', { ns: 'errors' });
      break
  }

  return errorMessage;
}