import { Dialog, DialogTitle, Link, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import CheckIcon from '@mui/icons-material/Check';
import { SuccessButton, SuccessTextGrid } from "./registerStyles"
import { DialogProps } from "../../../interfaces/interfaces"
import { useTranslation } from 'react-i18next';

export function Success(props: DialogProps) {
  const navigate = useNavigate();
  const { onClose, open } = props;

  const { t } = useTranslation('account');

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ backgroundColor: "paulini.blue", color: "white" }}>
        <Grid container direction="row">
          <Grid item xs={10}>{t('registrationSuccessful')}</Grid>
          <CheckIcon />
        </Grid>
      </DialogTitle>
      <SuccessTextGrid>
        • {t('confirmationEmailAndSmsSent')}
      </SuccessTextGrid>
      <SuccessTextGrid>
        • {t('refreshConfirmationTextPart1')} <Link onClick={() => navigate("/accSettings")}>{t('refreshConfirmationTextPart2Link')}</Link> {t('refreshConfirmationTextPart3')}
      </SuccessTextGrid>
      <SuccessButton variant="contained" onClick={() => navigate("/account")}>
        {t('successButtonAfterRegistration')}
      </SuccessButton>
    </Dialog>
  );
}