import { GenderItem } from "../interfaces/interfaces";
import i18next from '../i18n/i18n'
import { GenderType } from "orderme-api-integration-client";

export enum Gender {
    Female = "Female",
    Male = "Male",
    Unspecified = "Unspecified"
}

export const genderItems: Gender[] = [
    Gender.Female,
    Gender.Male,
    Gender.Unspecified
];

export const getGenderType = (gender: Gender): GenderType => {
    switch (gender) {
        case Gender.Male:
            return GenderType.Male;
        case Gender.Female:
            return GenderType.Female;
        default:
            return undefined;
    }
}

export const getGender = (gender: GenderType): Gender => {
    console.debug("getGender: " + gender);
    switch (gender) {
        case GenderType.Male:
            return Gender.Male;
        case GenderType.Female:
            return Gender.Female;
        default:
            return Gender.Unspecified;
    }
}

export const getGenderLabel = (gender: Gender) => {
    switch (gender) {
        case Gender.Female:
            return i18next.t('female', { ns: 'gender' });
        case Gender.Male:
            return i18next.t('male', { ns: 'gender' });
        case Gender.Unspecified:
            return i18next.t('unspecified', { ns: 'gender' });
        default:
            return gender;
    }
};