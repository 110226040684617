import { CardStyled, InputStyled } from "./inputStyles"

interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
}

export function Input(props: Props) {
    const { value, placeholder, className, onChange } = props;

    return (
        <CardStyled className={`${className}`} >
            <InputStyled placeholder={placeholder} onChange={(e: any) => onChange(e.target.value)} value={value} />
        </CardStyled>);
}