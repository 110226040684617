import { useEffect, useState } from "react";
import { Grid, Divider, useMediaQuery, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { RootState } from "../../redux/store";
import { CustomerInfo, ServiceItem } from "orderme-api-integration-client";
import { NavBar } from "../appBars/navBar/navBar"
import { PasswordChange } from "../auth/accountSettings/passwordChange"
import { CartInfo } from "./cartInfo"
import { LoginDialog } from "../auth/login/loginDialog";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { CartServicesList } from "./cartServicesList";
import { CartServicesEmpty } from "./cartServicesEmpty";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

export function ShoppingCart() {
  const navigate = useNavigate();
  const servicesInBasket: ServiceItem[] = useAppSelector((state: RootState) => state.cartState.servicesInBasket);
  const [cartData, setCartData] = useState<ServiceItem[]>([] as ServiceItem[]);
  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const isPasswordRestored: boolean = useAppSelector((state: RootState) => state.authState.isPasswordRestored);
  const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const [openLogin, setOpenLogin] = useState(false);
  const { t } = useTranslation('payment');
  const useTwoColumns = useMediaQuery('(min-width:900px)');

  useEffect(() => {
    createCartData();
  }, [servicesInBasket]);

  const createCartData = () => {
    const services = servicesInBasket.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setCartData(services);
  }

  const buy = () => {
    if (isPasswordRestored) {
      showPasswordChange();
      return;
    }

    if (!isUserLoggedIn) {
      setOpenLogin(true);
      return;
    }

    if (userInfo.ordersUsingAddressesOnly) {
      navigate('/addressBook', { state: true })
    }
    else {
      navigate("/whereTo");
    }
  }

  const showPasswordChange = () => {
    setOpenPasswordChange(true);
  }

  const closePasswordChange = () => {
    setOpenPasswordChange(false);
  }

  const closeLogin = () => {
    setOpenLogin(false);
  }

  const websiteServicesGrid = () => {
    return (
      <Grid
        container={useTwoColumns}
        justifyContent="center"
        direction={useTwoColumns ? "row" : "column"}
        sx={{ width: "100%" }}>
        <Grid item xs={6}>
          <CartServicesList services={cartData} />
        </Grid>
        <Grid item xs="auto" sx={{
          mt: "16px",
          mb: "8px"
        }}>
          <Divider orientation="vertical"
            sx={{
              borderRightWidth: "5px",
              width: "10px",
              display: !useTwoColumns ? "none" : null,
              height: "100%"
            }} />
        </Grid>
        <Grid item xs position="sticky" sx={{ top: 0, mt: useTwoColumns ? "16px" : "0px", height: "100%", width: "100%" }}>
          <Box sx={{ mx: "16px" }}><CartInfo /></Box>
          {useTwoColumns && (<StickyCartBar sx={{ mx: "17px", mb: "10px" }} open={buy} isPurchase={true} />)}
        </Grid>
      </Grid>)
  }

  const mobileServicesGrid = () => {
    return (
      <Grid container justifyContent="center">
        <Box sx={{ mx: "16px", width: "100%" }}>
          <CartServicesList services={cartData} />
        </Box>
        <Box sx={{ mx: "16px", width: "100%" }}>
          <CartInfo />
        </Box>
      </Grid>)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <NavBar barTitle={t("cart")} open={() => navigate("/home")} />
      <BreadcrumbsBar sx={{ maxWidth: "100%" }} to={"cart"} />
      <Grid className={appStyleMode === AppStyle.Mobile && "shopping-cart"}
        sx={{ backgroundColor: "white", color: "black", borderRadius: "15px", width: "100%" }} >
        <Grid container justifyContent="center" alignItems="center">
          {servicesInBasket.length === 0 ?
            <CartServicesEmpty /> :
            appStyleMode === AppStyle.Mobile ? mobileServicesGrid() : websiteServicesGrid()}
        </Grid>

        {
          appStyleMode === AppStyle.Mobile ?
            (<BottomCartBar open={buy} isDisabled={servicesInBasket.length < 1} isPurchase={true} />) :
            !useTwoColumns && (<StickyCartBar open={buy} isDisabled={servicesInBasket.length < 1} isPurchase={true} />)
        }

        <PasswordChange open={openPasswordChange} onClose={closePasswordChange} />
        <LoginDialog open={openLogin} onClose={() => closeLogin()} />
      </Grid>
    </Grid>
  );
}