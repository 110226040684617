import { emailPattern, phonePattern, phonePatternLT } from "./regexExpressions";

export const isValidPhoneNumber = (value: string): boolean => {
    if (!value)
        return false;

    if (value.startsWith('+370')) {
        return phonePatternLT.test(value);
    }

    return phonePattern.test(value);
};

export const isValidEmail = (value: string): boolean => {
    return value && emailPattern.test(value);
};