import { Typography, Grid, CircularProgress, DialogContent, Dialog } from "@mui/material";
import { PaymentStatus } from "./paymentEnum"
import { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from "react-router";
import { clearCart } from "../../redux/reducers/cartReducer";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "../../redux/hooks";
import { clearDelivery } from "../../redux/reducers/pickupReducer";
import { assignCustomerDetails } from "../../helpers/customerHelper";

interface LoadingProps {
  open: boolean;
  onClose: () => void;
  paymentStatus: PaymentStatus,
  timeout: number
}

export function PaymentDialog(props: LoadingProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onClose, open, paymentStatus, timeout } = props;
  const [dialogContent, setDialogContent] = useState<JSX.Element>();
  const [disableClose, setDisableClose] = useState<boolean>(true);
  const { t } = useTranslation('payment');

  useEffect(() => {
    processPaymentStatus();
  }, [paymentStatus])

  const processPaymentStatus = () => {
    switch (paymentStatus) {
      case PaymentStatus.Loading:
        setDisableClose(true);
        setDialogContent(loadingContent());
        break;
      case PaymentStatus.Success:
        setDisableClose(false);
        setDialogContent(successContent());
        dispatch(clearCart());
        dispatch(clearDelivery());
        assignCustomerDetails();
        setTimeout(function () {
          onClose();
          navigate("/home", { replace: true });
        }, timeout);
        break;
      case PaymentStatus.Failure:
        setDisableClose(false);
        setDialogContent(failureContent(t('failure')));
        dispatch(clearCart());
        dispatch(clearDelivery());
        setTimeout(function () {
          onClose();
          navigate("/orderHistory", { replace: true });
        }, timeout);
        break;
      case PaymentStatus.FailedSubmitOrder:
        setDisableClose(false);
        setDialogContent(failureContent(t('failedSubmitOrder')));
        break;
      default:
        setDisableClose(true);
        setDialogContent(loadingContent());
    }
  }

  const successContent = () => {
    return (
      <Grid sx={{ height: "150px" }} container direction="column" justifyContent="center" alignItems="center">
        <CheckCircleIcon sx={{ color: "paulini.green", height: "50px", width: "50px" }} />
        <Typography sx={{ color: "paulini.green", mt: "10px", textAlign: "center" }}>{t('success')}</Typography>
      </Grid>
    );
  }

  const failureContent = (message: string) => {
    return (
      <Grid sx={{ height: "150px" }} container direction="column" justifyContent="center" alignItems="center">
        <CancelIcon sx={{ color: "#ff6e63", height: "50px", width: "50px" }} />
        <Typography sx={{ color: "#ff6e63", mt: "10px" }}>{message}</Typography>
      </Grid>
    );
  }

  const loadingContent = () => {
    return (
      <Grid sx={{ height: "150px" }} container direction="column" justifyContent="center" alignItems="center">
        <CircularProgress sx={{ color: "paulini.orange" }} />
        <Typography sx={{ color: "paulini.orange", mt: "10px" }}>{t('loading')}</Typography>
      </Grid>
    );
  }

  return (
    <Dialog fullWidth open={open}
      onClose={() => {
        if (!disableClose) {
          onClose();
        }
      }}>
      <DialogContent>
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
}