import { Card } from "@mui/material";
import { styled } from '@mui/system';

export const CardStyled = styled(Card)({
    borderRadius: 10,
    borderColor: "#dfdfdf"
});

export const InputStyled = styled('input')({
    all: 'initial',
    width: 'calc(100% - 30px)',
    padding: "10px 10px 10px 15px",
    fontSize: 18,
    fontFamily: `"Roboto", sans-serif`,

    '&::placeholder': {
        fontStyle: 'italic',
        fontWeight: 'normal'
    }
});
