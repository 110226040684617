import { addToCart, clearCart } from "../redux/reducers/cartReducer";
import { store } from "../redux/store";
import { ServiceClient } from "./client";
import { getUserId } from "./customerHelper";
import { clearServiceIds, getCartServiceIds } from "./localStorageService";

const servicesClient: ServiceClient = new ServiceClient();

export const addServicesFromStorage = () => {
  const serviceIds = getCartServiceIds();
  if (!serviceIds?.length) {
    return;
  }

  servicesClient.getServices(getUserId()).then((response) => {
    var cart = store.getState().cartState.servicesInBasket;

    if (cart.length === 0) {
      const services = response.filter(s => serviceIds.includes(s.id));
      clearServiceIds();

      // Keep same order as in local storage
      services.sort((a, b) => {
        const indexA = serviceIds.indexOf(a.id);
        const indexB = serviceIds.indexOf(b.id);
        return indexA - indexB;
      });

      services.forEach(service => {
        var length = serviceIds.filter(s => s === service.id).length;
        for (var i = 0; i < length; i++) {
          store.dispatch(addToCart(service))
        }
      });
    }
  }).catch((err) => console.log(err))
}

export const refreshCartServices = () => {
  var cart = store.getState().cartState.servicesInBasket
  if (cart.length === 0)
    return;

  servicesClient.getServices(getUserId()).then((response) => {
    store.dispatch(clearCart());
    cart.forEach(service => {
      var newValue = response.find(s => s.id === service.id);
      if (newValue)
        store.dispatch(addToCart(newValue));
    })
  }).catch((err) => console.log(err))
}