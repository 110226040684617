import { Grid, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useTranslation } from 'react-i18next';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function NoElements() {
  const { t } = useTranslation('services');

  const getColor = () => {
    return appStyleMode === AppStyle.Paulini ? "white" : "gray";
  }

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column">
      <Typography color={getColor()} fontSize="20px">{t('empty')}</Typography>
      <SentimentDissatisfiedIcon
        sx={{
          color: getColor(),
          height: "100px",
          width: "100px"
        }} />
    </Grid>
  );
}
