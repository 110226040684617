import { AppBar, Grid } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { SocialNetworkIconButton } from "../appBarSyles";

export function SocialNetworkBar() {
    const openSite = (url: string) => {
        window.open(url, "_blank");
    }

    return (
        <AppBar elevation={0}
            sx={{
                backgroundColor: "paulini.blue",
                height: "auto",
                textAlign: "center",
                mt: "20px"
            }}
            position="static">
            <Grid>
                <SocialNetworkIconButton onClick={() => openSite("https://www.facebook.com/PAULINIvalykla/")}>
                    <FacebookIcon fontSize="large" />
                </SocialNetworkIconButton>
                <SocialNetworkIconButton onClick={() => openSite("https://www.instagram.com/paulini.lt/")}>
                    <InstagramIcon fontSize="large" />
                </SocialNetworkIconButton>
                <SocialNetworkIconButton onClick={() => openSite("https://www.youtube.com/@paulinidrabuziuvalykla8529")}>
                    <YouTubeIcon fontSize="large" />
                </SocialNetworkIconButton>
            </Grid>
        </AppBar >
    );
}