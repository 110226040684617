import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { RefreshTokenRequest } from 'orderme-api-integration-client';
import { store } from '../redux/store';
import { instance } from './client';
import { refreshToken } from '../redux/reducers/authReducer';

const refreshTokenRequest = () => {
  const state = store.getState();
  const request = {
    accessToken: state.authState.accessToken,
    refreshToken: state.authState.refreshToken,
  } as RefreshTokenRequest;

  return request;
}

const tokenRefresher = {
  setupInterceptors: () => {
    const refreshAuthLogic = async (failedRequest: any) => {

      var request = refreshTokenRequest();

      if (!request.accessToken || !request.refreshToken) {
        console.log("Access and refresh tokens are not available and could not be refreshed.");
      }
      else {
        try {
          const refreshTokenResponse = await store.dispatch(refreshToken()).unwrap();
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + refreshTokenResponse.accessToken;
          return Promise.resolve();
        }
        catch (error) {
          console.error(error);
        }
      };
    }
    createAuthRefreshInterceptor(instance, refreshAuthLogic);
  }
};

export default tokenRefresher;