import { Grid } from "@mui/material";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useTranslation } from 'react-i18next';

export function LoadFailed() {
  const { t } = useTranslation('errors');
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center"
      sx={{ height: window.innerHeight, backgroundColor: "paulini.blue" }}>
      <SentimentVeryDissatisfiedIcon sx={{ color: "white", fontSize: "50px" }} />
      <Grid sx={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>{t('noInternet')}</Grid>
    </Grid>);
}