import { Button } from "@mui/material";
import { CartBarBadgeItem } from "./cartBarBadgeItem";
import { useNavigate } from "react-router";

export function CartBarBadgeButton() {
    const navigate = useNavigate();

    return (
        <Button
            sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                px: "6px",
                '&:hover': {
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                }
            }}
            onClick={() => navigate("/cart")}>
            <CartBarBadgeItem />
        </Button>
    );
}