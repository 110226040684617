declare global {
    interface Window {
        env: any
    }
}

// change with your own variables
type EnvType = {
    PUBLIC_URL: string,
    REACT_APP_TYPE: string,
    REACT_APP_API_URL: string,
    REACT_APP_API_KEY: string,
    NODE_ENV: string
}

export const env: EnvType = { ...process.env, ...window.env }

export const isDevEnvironment = (): boolean => {
    return env.NODE_ENV === 'development';
}