import { useState, useEffect } from "react";
import { ListItem, List, Grid, Typography, DialogContent, Dialog, DialogTitle } from "@mui/material";
import { Country } from "orderme-api-integration-client";
import { CountryClient } from "../../../helpers/client"
import { CountryTypography } from "./registerStyles"
import { useTranslation } from 'react-i18next';

interface PhonePrefixSelectionProps {
  setSelectedCountry: (country: Country) => void;
  handleClose: () => void;
  open: boolean;
}

const defaultCountryName: string = "Lithuania";

export function PhonePrefixSelection(props: PhonePrefixSelectionProps) {
  const { setSelectedCountry, open, handleClose } = props;
  const [countries, setCountries] = useState<Country[]>();
  const countriesClient: CountryClient = new CountryClient();
  const { t } = useTranslation('account');

  const getPhonePrefixes = () => {
    countriesClient.get()
      .then((response) => {
        var defaultCountry = response.find(country => country.name === defaultCountryName);

        setCountries(response);
        setSelectedCountry(defaultCountry);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getPhonePrefixes();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ color: "white", backgroundColor: "paulini.orange" }}>
        {t('choosePhonePrefix')}
      </DialogTitle>
      <DialogContent>
        <List>
          {countries?.map((country, index) =>
            <ListItem button key={index} onClick={() => setSelectedCountry(country)}>
              <Grid>
                <CountryTypography>{country.name}</CountryTypography>
                <Typography>{country.phonePrefix}</Typography>
              </Grid>
            </ListItem >
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}