import { Button, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const RegisterButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.paulini.orange,
  height: "50px",
  width: "100%",
}));

export const ErrorGrid = styled(Grid)(({ theme }) => ({
  marginBottom: 20,
  color: theme.palette.paulini.error,
  marginLeft: 55,
  "&>p": {
    fontSize: 17
  },
  "&>p:first-child": {
    fontWeight: "bold"
  }
}));

export const CountryTypography = styled(Typography)({
  fontWeight: "bold",
  color: "gray",
  fontSize: "10px"
});

export const SuccessTextGrid = styled(Grid)({
  fontSize: "17px",
  margin: "10px"
});

export const SuccessButton = styled(Button)(({ theme }) => ({
  marginTop: "15px",
  backgroundColor: theme.palette.paulini.orange,
  color: "white"
}));