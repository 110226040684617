import { AppBar, Button, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const GridAlignItems = styled(Grid)({
  display: "flex",
  alignItems: "center"
});

export const AppBarBottom = styled(AppBar)({
  top: "auto",
  bottom: 0,
  backgroundColor: "#ff9102",
  height: 61
});

export const AppBarTypography = styled(Typography)({
  fontSize: 24,
  display: "inline-block"
});

export const GrayTypography = styled(Typography)({
  color: "gray"
});

export const AddServiceButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: theme.palette.paulini.orange,
  width: "17vh",
  borderRadius: 10
}));