import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function FriendsBadge() {
    const { t } = useTranslation('services');
    return (
        <Box sx={{
            backgroundColor: "paulini.orange",
            borderRadius: "10px",
            color: "white",
            width: "85px",
            pb: "2px",
            fontSize: "16px",
            textAlign: "center"
        }}>
            {t('priceToFriends')}
        </Box>
    );
};

export default FriendsBadge;