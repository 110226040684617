import { useLocation, useMatch } from "react-router-dom";

export default ({
    to = '',
    isRelativePath = false,
    query = {},
    pushToQuery = {},
    hash = '',
    keepOldQuery = false,
    state = {},
}) => {
    const location = useLocation();
    const match = useMatch("");

    let pathname;

    if (match && isRelativePath) {
        pathname = match.pathname + to;
    } else {
        pathname = to || location.pathname;
    }

    const newQuery = keepOldQuery
        ? new URLSearchParams(location.search)
        : new URLSearchParams();

    Object.entries(query).forEach(([key, value]) => {
        newQuery.set(key, value as string);
    });

    Object.entries(pushToQuery).forEach(([key, value]) => {
        const currentValue = newQuery.get(key);
        const splittedValue = currentValue ? currentValue.split(",") : [];
        splittedValue.push(value as string);

        newQuery.set(key, splittedValue as any);
    });

    return {
        pathname: pathname.replace(/\/\//g, "/"),
        search: newQuery.toString() ? `?${newQuery.toString()}` : "",
        hash,
        state,
    };
};