import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Container } from "@mui/material";
import { Popup } from "../../components/popup/popup";
import { CustomerClient } from "../../helpers/client"
import EmailConfirmIcon from "../../logo/emailconfirmation.png"
import { ConfirmPhoneButton, EmailConfirmImg } from "./styles"
import { Label } from "../../controls";
import { RootState } from "../../redux/store";
import { CustomerLoginInfo } from "orderme-api-integration-client";
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { forceLoginAgain } from "../../redux/reducers/authReducer";

const ConfirmEmail = ({ isOpened }: { isOpened: boolean }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const customersClient: CustomerClient = new CustomerClient();
    const loginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);
    const { t } = useTranslation('account');

    const sendEmail = async () => {
        setIsCodeSent(true);
        try {
            setIsBusy(true);
            await customersClient.sendEmailConfirmation();
            setTimeout(() => {
                dispatch(forceLoginAgain());
                setIsBusy(false);
            }, 3000);
        }
        catch (error) {
            setIsBusy(false);
            console.error("Failed to send confirmation email")
        }
    };

    return (
        <Popup open={isOpened} onClose={() => navigate(-1)} dialogTitle={t('confirmDialogTitle')}
            dialogContent={
                <>
                    <Container sx={{ margin: "20px 0", display: "flex" }}>
                        <Typography sx={{ fontSize: "20px" }}>
                            {t('missingEmailConfirmation')}
                        </Typography>
                        <EmailConfirmImg src={EmailConfirmIcon} alt="Email logo" />
                    </Container>
                    <Label sx={{ margin: "20px", fontWeight: "normal", fontSize: "20px", textAlign: 'left' }} type="success" content={t('sentEmail', { email: loginInfo.email })} isVisible={isCodeSent}></Label>
                </>
            }

            dialogActions={
                <>
                    {
                        !isCodeSent && <ConfirmPhoneButton disabled={isBusy} onClick={() => sendEmail()}>
                            <Typography sx={{ color: "white" }}>
                                {t('getConfirmationEmail')}
                            </Typography>
                        </ConfirmPhoneButton>
                    }
                </>
            } />
    );
}

export { ConfirmEmail };