import { InputBase, Grid, InputAdornment, Divider } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { SearchBarProps } from "../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';

export function SearchBar({ setSearch, searchValue }: SearchBarProps) {
  const { t } = useTranslation('services');

  return (
    <Grid sx={{ height: "56px", mt: "15px" }} container>
      <InputBase
        fullWidth
        autoFocus
        sx={{
          color: "black",
          backgroundColor: "white",
          px: "10px",
          borderRadius: "4px"
        }}
        placeholder={t('search')}
        value={searchValue}
        onChange={(e) => setSearch(e.target.value)}
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <ClearIcon
              sx={{ visibility: searchValue ? "visible" : "hidden", cursor: "pointer" }}
              onClick={() => setSearch("")} />
          </InputAdornment>
        )}
      />
      <Divider sx={{ width: "100%" }} />
    </Grid>
  );
}