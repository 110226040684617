import { Box, Typography } from "@mui/material";

export function MissingPayments() {
    return (
        <Box sx={{
            backgroundColor: "white",
            borderRadius: "15px",
            p: "40px",
            textAlign: "center",
            color: "black"
        }}>
            <Typography sx={{ color: "red", mt: "10px", fontSize: "32px" }}>Dėmesio!</Typography>
            <Typography sx={{ mt: "10px", fontSize: "20px" }}>Šiuo metu puslapyje neveikia mokėjimų sistema.</Typography>
            <Typography sx={{ mt: "10px", fontSize: "20px" }}>Drabužių valymą galite užsakyti skyriuje, bei atsiskaityti visais įprastais būdais.</Typography>
            <Typography sx={{ mt: "40px", fontSize: "20px" }}>Atsiprašome už laikinus nesklandumus.</Typography>
        </Box>
    );
}