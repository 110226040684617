import { useState } from "react";
import { CustomerClient } from "../../helpers/client";
import { RootState } from "../../redux/store";
import { CustomerInfo } from "orderme-api-integration-client";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import { CouponInput } from "./creditsStyles"
import { Button, Label } from "../../controls";
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";

export function ActivateCoupon() {
    const [coupon, setCoupon] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo)
    const customerClient = new CustomerClient();
    const { t } = useTranslation('credits');

    const activateCoupon = async () => {
        try {
            setIsSuccess(null);
            setIsBusy(true);
            await customerClient.useCoupon(customerInfo.id, coupon);
            await assignCustomerDetails();
            setIsSuccess(true);
            setCoupon('');
        }
        catch (error) {
            setIsSuccess(false);
        }
        finally {
            setIsBusy(false);
        }
    };

    return (
        <>
            <CouponInput placeholder={t('couponInput')} onChange={setCoupon} value={coupon} />
            <Grid sx={{ minHeight: "30px" }}>
                <Label sx={{ textAlign: 'right', mr: "20px" }} isVisible={isSuccess !== null} type={isSuccess ? 'success' : 'error'}
                    content={isSuccess ? t('creditsAdded') : t('invalidCoupon')} />
            </Grid>
            <Button onClick={() => activateCoupon()} isDisabled={isBusy || !coupon} content={t('activateCoupon')} />
        </>
    );
}