import { ListItemButton, Typography, CardContent, Avatar } from "@mui/material";
import 'moment/locale/lt';
import { roundDoubleNumbers } from "../../helpers/currency";
import { getStatusColor, getStatusTranslation } from "../../helpers/orderStatus";
import moment from "moment";
import { OrderItemProps } from "../../interfaces/interfaces";
import { useTranslation } from "react-i18next";
import { GridContainer, GridItemBox, OrderButtonIcon, OrderDateText, OrderItemCard, OrderPriceText, OrderStatusText } from "./orderHistoryItemStyles";

export function OrderHistoryItem(props: OrderItemProps) {
    const { order, open, destination } = props;
    const { t } = useTranslation('order');

    return (
        <OrderItemCard>
            <ListItemButton disableGutters onClick={() => open(order)}>
                <CardContent sx={{ width: "100%", padding: "10px" }}>
                    <GridContainer>
                        <GridItemBox>
                            <Typography sx={{ fontSize: "20px" }}>{t('order', { orderNumber: order.number })}</Typography>
                        </GridItemBox>
                        <GridItemBox>
                            <OrderStatusText>{getStatusTranslation(order.status)}</OrderStatusText>
                        </GridItemBox>
                        <GridItemBox>
                            <Avatar sx={{ width: "17px", height: "17px", backgroundColor: getStatusColor(order.status) }}>&nbsp;</Avatar>
                        </GridItemBox>
                        <GridItemBox>
                            <OrderDateText>{moment.utc(order.createdOn).local().locale("lt").format('YYYY/MM/DD HH:mm')}</OrderDateText>
                        </GridItemBox>
                        <GridItemBox>
                            <OrderPriceText>{roundDoubleNumbers(order.totalPrice)} €</OrderPriceText>
                        </GridItemBox>
                        <GridItemBox>
                            <OrderButtonIcon />
                        </GridItemBox>
                    </GridContainer>
                    <Typography sx={{ fontStyle: "italic", fontSize: "12px" }}>{destination?.name}</Typography>
                </CardContent>
            </ListItemButton>
        </OrderItemCard>
    );
}