import { Box, ListItemButton } from "@mui/material";
import { styled } from '@mui/system';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export const IconImage = styled('img')({
    height: "25px"
});

export const ListItemStyled = styled(ListItemButton)({
    '&:hover': {
        backgroundColor: "transparent",
    },
});

export const KeyArrowRightIconStyled = styled(KeyboardArrowRightIcon)({
    opacity: "0.5",
    position: "absolute",
    right: "10px"
});

export const CardBox = styled(Box)({
    width: "100%",
    margin: "10px 0px",
    borderRadius: "10px"
});