import { SxProps, Theme, Typography } from "@mui/material";

interface Props {
    content: string;
    type: 'success' | 'error',
    isVisible?: boolean;
    sx?: SxProps<Theme>;
}

export function Label(props: Props) {
    const { content, type, isVisible = true, sx = [] } = props;

    return (isVisible && (<Typography
        sx={
            [
                {
                    color: type === "error" ? "paulini.error" : "paulini.green",
                    textAlign: "center",
                    fontStyle: "italic"
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]
        }> {content}</Typography >));
}