import { IconButton } from "@mui/material";

interface HomeBarItemProps {
  open: () => void;
  icon: any;
  isVisible?: boolean;
}

export function HomeBarItem(props: HomeBarItemProps) {
  const { open, icon, isVisible = true } = props;

  return (
    <IconButton
      sx={{
        color: "white",
        height: "45px",
        width: "45px",
        display: !isVisible && "none"
      }}
      onClick={() => open()}>
      {icon}
    </IconButton>
  );
}