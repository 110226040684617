import { IconButton, Tab, iconButtonClasses, tabClasses } from '@mui/material';
import { styled } from '@mui/system';

export const ScrollIconButton = styled(IconButton)({
  width: 20,
  color: `rgba(255, 255, 255, 1)`,
  [`&.${iconButtonClasses.disabled}`]: { color: `rgba(255, 255, 255, 0.3)` }
});

export const ServiceTab = styled(Tab)({
  padding: "6px 12px",
  opacity: 0.7,
  [`&.${tabClasses.selected}`]: { opacity: 1 }
});