import { LanguageItem } from "../interfaces/interfaces";
import LangLogoLT from "../logo/lang_lt.png"
import LangLogoEN from "../logo/lang_en.png"
import LangLogoRU from "../logo/lang_ru.png"
import i18next from "i18next";

export const availableLanguages: LanguageItem[] = [
    // Default language on top
    { key: "lt", name: "lt-LT", image: LangLogoLT },
    { key: "en", name: "en-US", image: LangLogoEN },
    //{ key: "ru", name: "ru-RU", image: LangLogoRU }
];

export const defaultLanguage: LanguageItem = availableLanguages[0];

export const getCurrentLanguage = () => {
    const currentLanguage = availableLanguages.find(lang => lang.key === i18next.language);
    if (currentLanguage) {
        return currentLanguage.name;
    }

    return defaultLanguage.name
}