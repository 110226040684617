import { GET_ENUMS } from "./router";
import useGetPopupState from "./hooks/useGetPopupState";
import { ConfirmPhone, ConfirmEmail } from "./accountConfirmation";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { CustomerLoginInfo } from "orderme-api-integration-client";
import { getUserId } from "../helpers//customerHelper";

const popups = {
  [GET_ENUMS.popup.confirmPhone]: ConfirmPhone,
  [GET_ENUMS.popup.confirmEmail]: ConfirmEmail,
};

const GetParameterPopups = () => {
  const { mountedPopup, isOpened } = useGetPopupState();
  const Component = popups[mountedPopup];
  const loginInfo: CustomerLoginInfo = useAppSelector((state: RootState) => state.customerState.loginInfo);

  const isValid = () => {
    if (!getUserId())
      return false;

    if (GET_ENUMS.popup.confirmPhone === mountedPopup && !loginInfo.phoneConfirmed)
      return true;
    else if (GET_ENUMS.popup.confirmEmail === mountedPopup && !loginInfo.emailConfirmed)
      return true;

    return false;
  }

  if (!Component) {
    return null;
  }

  return <Component isOpened={isOpened && isValid()} />;
};

export default GetParameterPopups;