import { LoginDialog } from "../auth/login/loginDialog"
import { HashRouter } from "react-router-dom"
import { RootState } from '../../redux/store';
import GetParameterPopups from '../../popups';
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { resetLoginRequired } from "../../redux/reducers/authReducer"
import { LoadFailed } from "../loadFailed/loadFailed"
import MobileRoutes from "../routes/mobileRoutes"
import { CookiesBar } from "../cookies/cookiesBar";
import { VersionUpdate } from "../version/versionUpdate";

function MobileContent() {
    var dispatch = useAppDispatch();
    const isDisconnected: boolean = useAppSelector((state: RootState) => state.appLoaderState.isDisconnected);
    const isLoginRequired: boolean = useAppSelector((state: RootState) => state.authState.isLoginRequired);
    const isLatestAppVersion: boolean = useAppSelector((state: RootState) => state.appLoaderState.isLatestAppVersion);
    const isLoaded: boolean = useAppSelector((state: RootState) => state.appLoaderState.isLoaded);

    const closeLogin = () => {
        dispatch(resetLoginRequired());
    }

    return (isDisconnected ?
        <LoadFailed /> :
        !isLatestAppVersion ?
            <VersionUpdate /> :
            <HashRouter>
                <MobileRoutes />
                <GetParameterPopups />
                <LoginDialog open={isLoginRequired} onClose={closeLogin} />
                {isLoaded && <CookiesBar />}
            </HashRouter >
    )
}

export default MobileContent;
