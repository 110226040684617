import { Button, Typography } from "@mui/material";
import { styled } from '@mui/system';
import HistoryIcon from '@mui/icons-material/History';

export const PagingButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.paulini.orange,
  color: "white"
}));

export const HistoryIconStyled = styled(HistoryIcon)(({ theme }) => ({
  height: 100,
  width: 100,
  color: theme.palette.paulini.blue
}));

export const NoOrdersText = styled(Typography)({
  fontStyle: "italic",
  color: "gray",
  margin: "20px 0px"
});