import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DialogProps } from "../../../interfaces/interfaces"
import { CustomerClient } from "../../../helpers/client";
import { Popup } from "../../popup/popup";
import { ConfirmButton } from "./accountSettingsStyles";
import { useTranslation } from 'react-i18next';
import { ExternalAuthenticationType } from "orderme-api-integration-client";
import { forceLoginAgain } from "../../../redux/reducers/authReducer";
import { useAppDispatch } from "../../../redux/hooks";

export interface RevokeLoginDialogProps extends DialogProps {
    externalAuthType: ExternalAuthenticationType;
}

export function ExternalLoginRevoke(props: RevokeLoginDialogProps) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('account');
    const { onClose, open, externalAuthType: externalLoginType } = props;
    const customersClient: CustomerClient = new CustomerClient();
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        switch (externalLoginType) {
            case ExternalAuthenticationType.Facebook:
                setTitle(t('revokeFacebookLogin'));
                break;
            case ExternalAuthenticationType.Google:
                setTitle(t('revokeGoogleLogin'));
                break;
            case ExternalAuthenticationType.Apple:
                setTitle(t('revokeAppleLogin'));
                break;
            default:
                setTitle(null);
                break;
        }
    }, [externalLoginType]);

    const revokeExternalLogin = async () => {
        try {
            await customersClient.removeExternalAuthentication(externalLoginType);
            dispatch(forceLoginAgain());
        }
        catch (err) {
            console.error(err);
        }
    }

    return (
        <Popup open={open} onClose={onClose} dialogTitle={title} dialogActions={
            <Grid sx={{ height: "80px" }} container direction="row" justifyContent="space-evenly" alignItems="center">
                <ConfirmButton onClick={() => revokeExternalLogin()} variant="contained"> {t('confirmationYes')}</ConfirmButton>
                <ConfirmButton onClick={() => onClose()} variant="contained"> {t('confirmationNo')}</ConfirmButton>
            </Grid>} />
    );
}