import { createSlice } from "@reduxjs/toolkit";

interface IsMenuOpened {
    isMenuOpened: boolean;
}

const initialState = { isMenuOpened: false } as IsMenuOpened;

const menuSlice = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        openMenu: state => {
            state.isMenuOpened = true;
        },
        closeMenu: state => {
            state.isMenuOpened = false;
        }
    },
})

export const { openMenu, closeMenu } = menuSlice.actions
export default menuSlice.reducer