import { ListItem, Typography, Card, ListItemIcon } from "@mui/material";

interface CardElementProps {
    text: string,
    action?: () => void,
    icon: any;
    isButton?: boolean
}

export function CardElement(props: CardElementProps) {
    const { text, action, icon, isButton = true } = props;

    const canClick = () => {
        if (isButton) {
            return true as true;
        }
    }

    return (
        <Card sx={{ marginTop: "10px", padding: "8px" }}>
            <ListItem button={canClick()} onClick={() => isButton && action()}>
                <ListItemIcon sx={{ color: "paulini.orange" }}>
                    {icon}
                </ListItemIcon>
                <Typography sx={{ color: "#1f4a8f" }}>{text}</Typography>
            </ListItem>
        </Card>
    );
}