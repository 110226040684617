import { useEffect, useState } from "react";
import { Grid, Typography, List, ListItem } from "@mui/material";
import { CustomerAddress, CustomerInfo } from "orderme-api-integration-client";
import { AddressCard, CancelIconStyled, IconButtonStyled, SadIcon } from "./addressBookStyles"
import { RootState } from "../../redux/store";
import { assignCustomerDetails } from "../../helpers/customerHelper";
import { MyAddressesProps } from "../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from "../../redux/hooks";
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";

export function MyAddresses(props: MyAddressesProps) {
  const userInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const [clientAddresses, setClientAddresses] = useState<CustomerAddress[]>();
  const { updateAddresses, setCurrentAddress, currentAddress, isSelectable = false } = props;
  const { t } = useTranslation('address');

  useEffect(() => {
    assignAddresses();
  }, [userInfo]);

  const assignAddresses = () => {
    if (Object.keys(userInfo).length === 0) {
      assignCustomerDetails();
    }
    else {
      let addresses = userInfo?.addresses || [];
      setClientAddresses(addresses.map(item => item.address));
    }
  }

  const removeAddress = (index: number) => {
    var customerAddresses: CustomerAddress[] = clientAddresses;
    customerAddresses.splice(index, 1);

    updateAddresses(customerAddresses);
  }

  const isSelected = (item: CustomerAddress) => {
    return isSelectable &&
      currentAddress?.address === item.address &&
      currentAddress?.apartmentNumber === item.apartmentNumber;
  }

  return (
    <>
      {clientAddresses?.length > 0 ? (
        <List className={appStyleMode === AppStyle.Mobile && "address-book-my-addresses-list"}>
          {clientAddresses.map((item, index) => (
            <Grid key={index} container justifyContent="center" alignItems="center">
              <ListItem disableGutters={true} sx={{ px: "20px" }}>
                <AddressCard
                  onClick={() => setCurrentAddress(item)}
                  sx={{ backgroundColor: isSelected(item) ? "paulini.orange" : "none", color: isSelected(item) ? "white" : "black" }}>
                  <IconButtonStyled onClick={() => removeAddress(index)}>
                    <CancelIconStyled />
                  </IconButtonStyled>
                  <Typography sx={{ padding: "10px 10px 10px 20px" }}>{item.address} {item.apartmentNumber}</Typography>
                </AddressCard>
              </ListItem>
            </Grid>
          ))}
        </List>) : (
        <Grid container alignItems="center" direction="column" sx={{ mt: "20px" }}>
          <Typography sx={{ fontSize: "20px", color: "gray" }}>{t('missingAddress')}</Typography>
          <SadIcon />
        </Grid>)}
    </>
  );
}