import PauliniLogo from "../../logo/PAULINI-blue.svg"
import { ImageLoader } from "../imgLoader/imgLoader";
import { GridLayout } from "./initialPageStyles";
import CircularProgress from '@mui/material/CircularProgress';

export function StartPage() {

  return (
    <GridLayout container direction="column" sx={{ backgroundColor: "white" }} justifyContent="center" alignItems="center">
      <ImageLoader sx={{ width: "240px", position: "absolute" }} src={PauliniLogo} alt="Logotipas" />
      <CircularProgress sx={{ position: "absolute", color: "#0096d6" }} size={240} thickness={1} />
    </GridLayout>
  );
}