import { Typography } from "@mui/material";
import { GridLayout } from "../initialPage/initialPageStyles";
import { ImageLoader } from "../imgLoader/imgLoader";
import PauliniLogo from "../../logo/logo.png"
import { OrangeButton } from "../../controls/button/buttonStyles";
import { useTranslation } from "react-i18next";

declare let window: any;

export function VersionUpdate() {
    const { t } = useTranslation('info');

    const openUpdateApp = () => {
        if (window.cordova) {
            window.cordova.InAppBrowser.open("https://onelink.to/5htyta", "_system", "location=yes");
        }
    };

    return (
        <GridLayout container direction="column" justifyContent="center" alignItems="center">
            <ImageLoader sx={{ width: "200px", ml: "-20px" }} src={PauliniLogo} alt="Logotipas" />
            <Typography sx={{ color: "white", textAlign: "center", mt: "20px" }}>{t('newAppVersionAvailable')}</Typography>
            <Typography sx={{ color: "white", textAlign: "center", mb: "20px" }}>{t('requestAppUpdate')}</Typography>
            <OrangeButton onClick={openUpdateApp} variant="contained">{t('updateApp')}</OrangeButton>
        </GridLayout>
    );
};