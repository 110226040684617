import { Card, List, ListItem, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const AchievementText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "bold",
  backgroundColor: theme.palette.paulini.orange,
  color: "white",
  borderRadius: "2px",
  padding: "4px",
  margin: "2px",
  boxShadow: "1px 1px 2px gray"
}));

export const ListItemStyled = styled(ListItem)({
  display: "block",
  marginTop: "10px",
  marginBottom: "10px"
});

export const CardStyled = styled(Card)({
  boxShadow: "1px 1px 2px gray",
  padding: "4px",
  borderRadius: "10px"
});

type ListStyledProps = {
  isWebsite: boolean
}

export const ListStyled = styled(List)<ListStyledProps>(({ isWebsite }) => ({
  overflow: "auto",
  paddingTop: "0px",
  maxWidth: isWebsite ? "1000px" : null,
  width: "100%"
}));