import { Typography } from "@mui/material";
import { Popup } from "../popup/popup";
import { useTranslation } from 'react-i18next';
import { ActionsContainerGrid, AmountLabel, CancelButton, ConfirmButton, DialogContainerGrid } from "./paymentConfirmDialogStyles";

interface DialogProps {
    open: boolean;
    amount: number;
    onClose: (isConfirmed: boolean) => void;
}

export function PaymentConfirmDialog(props: DialogProps) {
    const { open, onClose, amount } = props;
    const { t } = useTranslation('payment');

    return (<Popup open={open} onClose={() => onClose(false)} closeButton={false} dialogTitle={t('attention')}
        dialogContent={
            <DialogContainerGrid>
                <Typography>
                    {t('creditsWillBeUsed')}
                </Typography>
                <AmountLabel>
                    {amount} €
                </AmountLabel>
                <Typography>
                    {t('continueQ')}
                </Typography>
            </DialogContainerGrid>
        }
        dialogActions={
            <ActionsContainerGrid container>
                <CancelButton content={t('no')} onClick={() => { onClose(false) }} />
                <ConfirmButton content={t('yes')} onClick={() => { onClose(true) }} />
            </ActionsContainerGrid>
        }
    />
    );
}