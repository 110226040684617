import { Button } from "@mui/material";
import { styled } from '@mui/system';

export const ButtonStyled = styled(Button)({
    color: "white",
    borderRadius: "10px",
    "&:disabled": {
        color: 'white',
        backgroundColor: "#9f9f9f"
    },
    "&:hover": {
        boxShadow: "1px 1px 5px grey"
    }
});

export const GreenButton = styled(ButtonStyled)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.green,
    "&:hover": {
        backgroundColor: theme.palette.paulini.greenHover
    }
}));

export const OrangeButton = styled(ButtonStyled)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.orange,
    "&:hover": {
        backgroundColor: theme.palette.paulini.orangeHover
    }
}));

export const BlueButton = styled(ButtonStyled)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.blue,
    "&:hover": {
        backgroundColor: theme.palette.paulini.blueHover
    }
}));

export const RedButton = styled(ButtonStyled)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.error,
    "&:hover": {
        backgroundColor: "#bd2828"
    }
}));

export const WhiteButton = styled(ButtonStyled)({
    backgroundColor: "white",
    color: "black",
    "&:hover": {
        backgroundColor: "#eeeeee"
    }
});
