import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    tabValue: 0,
} as LastTab


export interface LastTab {
    tabValue: number;
}

const tabSlice = createSlice({
    name: 'tabs',
    initialState: initialState,
    reducers: {
        setTabValue: (state, action: PayloadAction<number>) => {
            state.tabValue = action.payload;
        },
    },
})

export const { setTabValue } = tabSlice.actions
export default tabSlice.reducer
