import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const DiscountImage = styled('img')({
    width: 40,
    height: 60,
    position: "absolute",
    left: "auto",
    right: 20,
    top: 0
});

export const DiscountTypography = styled(Typography)({
    color: "White",
    fontSize: "14px",
    position: "absolute",
    left: "auto",
    right: "22px",
    top: "30px",
    fontWeight: "bold"
});