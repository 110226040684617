import { Button, Grid, Typography } from "@mui/material";
import { Popup } from "../popup/popup";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

interface ResetPasswordProps {
  open: boolean;
  onClose: () => void;
}

declare let window: any;

export function RequestPermissionDialog(props: ResetPasswordProps) {
  const { open, onClose } = props;
  const { t } = useTranslation('permission');

  const openSettings = () => {
    if (window.cordova && window.cordova.plugins.settings) {
      window.cordova.plugins.settings.open("application_details", function () {
        console.log('opened settings');
      },
        function () {
          console.log('failed to open settings');
        });
    }
  }

  return (
    <>
      <Popup open={open} onClose={() => onClose()} dialogTitle={t('warning')} dialogContent={
        <Grid container justifyContent="center" sx={{ marginTop: "5px" }} direction="row">
          <Typography sx={{ textAlign: "center", display: "inline-block" }}>{t('acceptPermission')}</Typography>
          <Button sx={{ marginTop: "5px" }} variant="outlined" onClick={() => openSettings()} startIcon={<SettingsIcon sx={{ color: "gray" }} />}>{t('settings')}</Button>
        </Grid>
      }
        onAction={() => onClose()}
        buttonContent={t('confirmButton')}
        isButtonDisabled={false}
        closeButton={false}
      />
    </>
  );
}