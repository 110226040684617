import { Grid } from "@mui/material";
import { DialogProps } from "../../../interfaces/interfaces"
import { Popup } from "../../popup/popup";
import { ButtonStyled } from "./logoutConfirmationStyles";
import { useTranslation } from 'react-i18next';
import { store } from "../../../redux/store";
import { logout as authLogout } from "../../../redux/reducers/authReducer";

export function LogoutConfirmation(props: DialogProps) {
  const { onClose, open } = props;
  const { t } = useTranslation('account');

  const handleClose = () => {
    onClose();
  };

  const logout = async () => {
    store.dispatch(authLogout());
    onClose();
  }

  return (
    <Popup onClose={() => handleClose} open={open} closeButton={false} dialogTitle={t('confirmationLogout')}
      dialogActions={
        <Grid sx={{ height: "80px" }} container direction="row" justifyContent="space-evenly" alignItems="center">
          <ButtonStyled onClick={() => logout()} variant="contained">{t('confirmationYes')}</ButtonStyled>
          <ButtonStyled onClick={() => onClose()} variant="contained">{t('confirmationNo')}</ButtonStyled>
        </Grid>
      } />
  );
}