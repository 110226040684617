import { Avatar, Box, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { Button } from "../../controls";

export const InfoIconStyled = styled(Avatar)({
    height: 30,
    width: 30,
    display: 'inline-block'
});

export const TagIconStyled = styled('img')({
    position: "sticky",
    height: 12,
    marginBottom: -12
});

export const ProgressIconStyled = styled('img')({
    width: "100%",
    height: 20
});

export const UnpaidAreaGrid = styled(Grid)({
    marginTop: 20,
    marginBottom: 20
});

type OrderDetailsGridProps = {
    isMobile: boolean
}

export const OrderDetailsGrid = styled(Grid)<OrderDetailsGridProps>(({ isMobile }) => ({
    height: "auto",
    overflow: "auto",
    maxWidth: "1000px",
    width: "100%",
    backgroundColor: "white",
    borderRadius: "15px",
    color: "black",
    paddingBottom: !isMobile ? "10px" : "0px"
}));

export const OrderStatusAreaGrid = styled(Grid)({
    marginBottom: 10,
    display: "flex",
    padding: "0 8px 0 5px"
});

export const HeaderText = styled(Typography)({
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontSize: 20
});

export const DetailDatesBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    "&>div:nth-child(2)": {
        "&>p:first-child": {
            fontWeight: "bold"
        }
    }
});

export const PaymentButton = styled(Button)({
    width: '175px'
});

export const ProceedPaymentButton = styled(PaymentButton)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.orange,
    margin: 20
}));

export const CancelPaymentButton = styled(PaymentButton)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.error,
}));

export const Separator = styled(Box)({
    border: "2px dashed #cfcfcf",
    borderBottom: 0,
    marginTop: 10,
    marginBottom: 10
});

export const OldPriceTypography = styled(Typography)({
    display: "inline-block",
    textDecoration: "line-through",
    opacity: 0.7,
    marginRight: 5
});

export const Line = styled(Box)({
    border: "solid",
    borderWidth: 0.1,
    opacity: 0.1
});

export const DiscountTextTypography = styled(Typography)({
    textAlign: 'center',
    border: "2px dashed #cfcfcf",
    borderRight: "none",
    borderLeft: "none",
    marginTop: 5,
    marginBottom: 5
});

export const InfoItemValue = styled(Typography)({
    fontStyle: 'italic',
    fontSize: 17,
    textAlign: 'right'
});

export const InfoItemName = styled(Typography)({
    fontSize: 12,
    textAlign: 'right',
    fontStyle: 'italic',
    color: "gray"
});