import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import i18next from "i18next";
import { AuthenticationRequest, AuthenticationResponse, ExternalAuthenticationRequest, ExternalAuthenticationType, RefreshTokenRequest } from "orderme-api-integration-client";
import { CustomerClient, instance } from "../../helpers/client";
import { refreshTokenUri } from "../../helpers/clientConfigs";
import { DecodedToken, GoogleLoginError } from "../../interfaces/interfaces";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";
import { TokenStorage } from "../../helpers/tokenStorage";

const customerClient: CustomerClient = new CustomerClient();

export const authenticate = createAsyncThunk(
    'auth/authenticate',
    async ({ input, password }: { input: string, password: string }, thunkAPI) => {
        try {
            let authenticationRequest = {
                input: input,
                password: password
            } as AuthenticationRequest;
            const response = await customerClient.authenticate(authenticationRequest);
            return response;
        }
        catch (error) {
            console.error(error);
            return thunkAPI.rejectWithValue(i18next.t('errors:badLogin'));
        }
    },
)

export const authenticateFromFacebook = createAsyncThunk(
    'auth/authenticateFromFacebook',
    async (externalAuthRequest: ExternalAuthenticationRequest, thunkAPI) => {
        try {
            const response = await customerClient.authenticateFromExternal(
                externalAuthRequest, ExternalAuthenticationType.Facebook);
            return response;
        }
        catch (error) {
            console.error(error);
            return thunkAPI.rejectWithValue("User is not registered with Facebook account.");
        }
    },
)

export const authenticateFromGoogle = createAsyncThunk(
    'auth/authenticateFromGoogle',
    async (externalAuthRequest: ExternalAuthenticationRequest, thunkAPI) => {
        try {
            const response = await customerClient.authenticateFromExternal(
                externalAuthRequest, ExternalAuthenticationType.Google);
            return response;
        }
        catch (error: any) {
            console.error(error);
            return thunkAPI.rejectWithValue(
                {
                    message: "User is not registered with Google account",
                    userGrantRequired: error.userGrantRequired === true
                } as GoogleLoginError);
        }
    },
)

export const authenticateFromApple = createAsyncThunk(
    'auth/authenticateFromApple',
    async (externalAuthRequest: ExternalAuthenticationRequest, thunkAPI) => {
        try {
            const response = await customerClient.authenticateFromExternal(
                externalAuthRequest, ExternalAuthenticationType.Apple);
            return response;
        }
        catch (error) {
            console.error(error);
            return thunkAPI.rejectWithValue("User is not registered with Apple account.");
        }
    },
)

export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (_: void, thunkAPI) => {
        try {
            var state = thunkAPI.getState() as RootState;
            const request = {
                accessToken: state.authState.accessToken,
                refreshToken: state.authState.refreshToken
            } as RefreshTokenRequest;

            const response = await instance.post<AuthenticationResponse>(refreshTokenUri, request, { skipAuthRefresh: true } as AxiosAuthRefreshRequestConfig);
            return response.data;
        }
        catch (error) {
            console.debug(error);
        }
    },
)

export interface AuthState {
    accessToken: string,
    refreshToken: string,
    isLoggedIn: boolean;
    isLoginRequired: boolean;
    isPasswordRestored: boolean;
    rememberMe: boolean;
    loading: boolean;
    error: string;
}

const initialState: AuthState = {
    accessToken: TokenStorage.Instance.getAccessToken(),
    refreshToken: TokenStorage.Instance.getRefreshToken(),
    isLoggedIn: false,
    isLoginRequired: false,
    isPasswordRestored: false,
    rememberMe: false,
    loading: false,
    error: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        removeLoginError: (state) => {
            state.error = null;
        },
        login: (state) => {
            state.isLoggedIn = true;
            state.isLoginRequired = false;
            state.isPasswordRestored = mustChangePassword(state.accessToken);
        },
        logout: (state) => {
            updateTokens(state, null);
        },
        forceLoginAgain: (state) => {
            updateTokens(state, null);
            state.isLoginRequired = true;
        },
        resetLoginRequired: (state) => {
            state.isLoginRequired = false;
        },
        setRememberMe: (state, action: PayloadAction<boolean>) => {
            state.rememberMe = action.payload;
        },
        reloadTokenFromStorage: (state) => {
            state.accessToken = TokenStorage.Instance.getAccessToken();
            state.refreshToken = TokenStorage.Instance.getRefreshToken();
            state.isLoggedIn = false;
            state.isLoginRequired = false;
            state.isPasswordRestored = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authenticate.fulfilled, (state, action) => {
            updateTokens(state, action.payload);
            state.loading = false;
        });
        builder.addCase(authenticate.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            updateTokens(state, null);
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(authenticateFromFacebook.fulfilled, (state, action) => {
            updateTokens(state, action.payload);
            state.loading = false;
        });
        builder.addCase(authenticateFromFacebook.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authenticateFromFacebook.rejected, (state) => {
            updateTokens(state, null);
            state.loading = false;
        });
        builder.addCase(authenticateFromGoogle.fulfilled, (state, action) => {
            updateTokens(state, action.payload);
            state.loading = false;
        });
        builder.addCase(authenticateFromGoogle.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authenticateFromGoogle.rejected, (state) => {
            updateTokens(state, null);
            state.loading = false;
        });
        builder.addCase(authenticateFromApple.fulfilled, (state, action) => {
            updateTokens(state, action.payload);
            state.loading = false;
        });
        builder.addCase(authenticateFromApple.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(authenticateFromApple.rejected, (state) => {
            updateTokens(state, null);
            state.loading = false;
        });
        builder.addCase(refreshToken.fulfilled, (state, action) => {
            updateTokens(state, action.payload);
            state.loading = false;
        });
        builder.addCase(refreshToken.rejected, (state) => {
            updateTokens(state, null);
            state.loading = false;
        });
    },
})

function updateTokens(state: AuthState, response: AuthenticationResponse) {
    if (response) {
        state.refreshToken = response.refreshToken;
        state.accessToken = response.accessToken;
        state.isLoggedIn = true;
        TokenStorage.Instance.update(response.accessToken, response.refreshToken, state.rememberMe);
    }
    else {
        state.refreshToken = null;
        state.accessToken = null;
        state.isLoggedIn = false;
        TokenStorage.Instance.clear();
    }

    state.isLoginRequired = false;
    state.isPasswordRestored = mustChangePassword(state.accessToken);
}

function mustChangePassword(accessToken: string) {
    if (accessToken) {
        var decoded: DecodedToken = jwtDecode(accessToken);
        var mustChangePassword: boolean = decoded.orderme_mustChangePassword;
        if (!mustChangePassword)
            return false;

        return mustChangePassword;
    }

    return false;
}

export const { logout, login, forceLoginAgain, resetLoginRequired, setRememberMe, reloadTokenFromStorage, removeLoginError } = authSlice.actions
export default authSlice.reducer