import { Box, Card, Typography } from "@mui/material";
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const GridContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "auto 70px 20px"
});

export const GridItemBox = styled(Box)({
    display: "flex",
    alignItems: "center"
});

export const OrderItemCard = styled(Card)({
    width: window.innerWidth - 30,
    margin: 10,
    backgroundColor: '#fcfcfc'
});

export const OrderDateText = styled(Typography)({
    fontSize: 10,
    marginBottom: 3,
    fontStyle: "italic"
});

export const OrderStatusText = styled(Typography)({
    fontSize: 11
});

export const OrderPriceText = styled(Typography)({
    fontSize: 15,
    fontWeight: "bold",
    display: "inline-block",
    alignItems: "center",
    verticalAlign: "middle"
});

export const OrderButtonIcon = styled(ArrowForwardIosIcon)({
    color: "gray",
    height: 20,
    width: 20
});