import { Typography, Grid, List } from "@mui/material";
import { AchievementText, ListItemStyled, CardStyled } from "./achievementStyles"
import { AchievementItemProps } from "../../interfaces/interfaces"

export function AchievementItem(props: AchievementItemProps) {
  const { achievement } = props;

  const splitNumbers = (number: number) => {
    var value = number.toString();
    return value.split("");
  }

  return (
    <ListItemStyled>
      <CardStyled>
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <List>
            {splitNumbers(achievement.quantity).map((item, index) => {
              return (
                <Grid key={index} sx={{ display: "inline-block" }}>
                  <AchievementText>{item}</AchievementText>
                </Grid>
              )
            })}
          </List>
          <Typography>{achievement.name}</Typography>
        </Grid>
      </CardStyled>
      <Grid container direction="column" justifyContent="center" alignItems="center">
      </Grid>
    </ListItemStyled>
  );
}