import { Card, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const FilterLabel = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#ffa938",
  color: "white"
});

export const FilterCard = styled(Card)(({ theme }) => ({
  minWidth: "150px",
  backgroundColor: theme.palette.paulini.orange,
  width: "auto",
  marginTop: "5px",
  marginBottom: "5px"
}));