import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { Button } from "../../controls";

export const DialogContainerGrid = styled(Grid)({
    padding: 20,
    textAlign: "center"
});

export const AmountLabel = styled(Typography)({
    fontWeight: "bold",
    fontSize: 26,
    margin: "10px 0 10px 0"
});

export const ConfirmButton = styled(Button)({
    flexGrow: 1,
    height: 50,
    borderRadius: "0 5px 5px 0 !important"
});

export const CancelButton = styled(Button)({
    flexGrow: 1,
    backgroundColor: "#e51d20 !important",
    borderRadius: "5px 0 0 5px !important",
    "&:hover": {
        backgroundColor: "#881012 !important"
    }
});

export const ActionsContainerGrid = styled(Grid)({
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    flexWrap: "nowrap"
});