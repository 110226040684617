import { CustomerClient } from "./client"
import { store } from "../redux/store"
import { updateCustomer } from "../redux/reducers/customerReducer";

export const assignCustomerDetails = async () => {
    var customerClient = new CustomerClient();
    try {
        const response = await customerClient.getInfo();
        store.dispatch(updateCustomer(response));
        return response;
    } catch (err) {
        return console.log(err);
    }
}

export const getLoggedInState = () => {
    return store.getState().authState.isLoggedIn;
}

export const getCustomer = () => {
    return store.getState().customerState.customerInfo;

}

export const getUserId = () => {
    if (getLoggedInState()) {
        return getCustomer().id;
    }

    return undefined;
}
