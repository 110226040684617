import { Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";

interface HomeBarLinkProps {
    link: string;
    text: string;
    disableMargin?: boolean;
}

export const HomeBarLink: React.FC<HomeBarLinkProps> = ({ link, text, disableMargin }) => {
    const navigate = useNavigate();
    const isSelected = useLocation().pathname.endsWith(link);

    const handleClick = () => {
        navigate(link);
    }

    return (
        <Link underline="none" sx={{
            color: "white",
            mx: disableMargin ? "0px" : "40px",
            borderBottomWidth: isSelected && "5px",
            mt: isSelected && "5px",
            borderBottomStyle: "solid",
            borderBottomColor: "paulini.orange"
        }} component="button" onClick={handleClick}>
            <Typography sx={{ textTransform: "uppercase" }}>{text}</Typography>
        </Link>
    );
}