import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { InfoFeed } from "orderme-api-integration-client";
import { Linear } from "../progressBars/linear";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { HtmlViewer } from "../../controls/html/htmlViewer";
import { InfoFeedClient } from "../../helpers/client";
import { useNavigate, useParams } from "react-router";

export function NewsItem() {
    const navigate = useNavigate();
    const infoFeedClient = new InfoFeedClient();
    const params = useParams<"id">();
    const [infoFeedId, setInfoFeedId] = useState<string>();
    const [infoFeed, setInfoFeed] = useState<InfoFeed>();

    useEffect(() => {
        var id = params.id as string;
        setInfoFeedId(id);
    }, [params])

    useEffect(() => {
        if (infoFeedId)
            getInfoFeed(infoFeedId);
        else
            setInfoFeed(null);

    }, [infoFeedId])

    const getInfoFeed = async (id: string) => {
        try {
            setInfoFeed(await infoFeedClient.get(id));
        } catch (error) {
            console.error(`Error fetching news by ID=${id}:`, error);
            setInfoFeed(null);
            navigate("/");
        }
    };

    return (
        <Grid container direction="column" alignItems="center">
            {!infoFeed && (<Linear />)}
            <BreadcrumbsBar to={"news/newsItem"} currentPageName={infoFeed?.title} />
            {infoFeed &&
                <Grid sx={{
                    maxWidth: "1000px",
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "15px"
                }}>
                    <HtmlViewer content={infoFeed.description} />
                </Grid>
            }
        </Grid>
    );
}