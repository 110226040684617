import i18next from "../i18n/i18n";

export function updateDocumentTitle(newTitle?: string): void {
    if (newTitle)
        document.title = formatTitle(newTitle);
    else
        document.title = getTitleByLocationPath();
}

function getTitleByLocationPath(): string {
    const path = window.location.pathname.split("/").filter(Boolean).pop();
    if (path === "login")
        return formatTitle(i18next.t('account:login'));

    return "PAULINI drabužių valykla";
}

function formatTitle(title: string): string {
    return title + " | PAULINI";
}