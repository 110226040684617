import { useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, styled, useMediaQuery } from "@mui/material";
import { ServiceClient } from "../../helpers/client";
import { CustomerInfo, ServiceCategory, ServiceGroup } from "orderme-api-integration-client";
import { useLocation, useParams } from "react-router";
import { ServiceLogoItem } from "../serviceLogoItem/serviceLogoItem"
import { NoElements } from "../subCategories/noElements";
import { ServicesList } from "../selectedService/servicesList";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StickyCartBar } from "../appBars/cartBar/stickyCartBar";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

const ImagesGrid = styled(Grid)(({ theme }) => ({
    maxWidth: "420px",
    [theme.breakpoints.up(1300)]: {
        width: "820px",
        minWidth: "820px"
    },
    '@media (max-width: 740px)': {
        justifyContent: 'center'
    }
}));

export function SelectedCategory() {
    const location = useLocation();
    const params = useParams<"id">();
    const [categoryId, setCategoryId] = useState<string>();
    const [category, setCategory] = useState<ServiceCategory>();
    const [categoryGroups, setCategoryGroups] = useState<ServiceGroup[]>();
    const [currentGroup, setCurrentGroup] = useState<ServiceGroup>();
    const servicesClient: ServiceClient = new ServiceClient();
    const useTwoColumns = useMediaQuery('(min-width:900px)');
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);

    useEffect(() => {
        var newCategoryId = params.id as string;
        setCategoryId(newCategoryId);
    }, [params])

    useEffect(() => {
        if (!categoryId) {
            setCategoryGroups(null);
            setCategory(null);
            return;
        }
        servicesClient.getCategoryGroups(categoryId, customerInfo.id)
            .then((response) => {
                setCategoryGroups(response);
            })
            .catch((err) => {
                setCategoryGroups(null);
                console.log(err);
            });

        servicesClient.getCategories().then((response) => {
            setCategory(response.find((element) => element.id === categoryId));
        }).catch((err) => {
            setCategory(null);
            console.log(err);
        });
    }, [categoryId, customerInfo])

    useEffect(() => {
        if (categoryGroups && categoryGroups.length > 0) {
            if (location.state) {
                const groupId = location.state as string;
                if (groupId) {
                    const group = categoryGroups.find((group) => group.id === groupId);
                    if (group) {
                        setCurrentGroup(group);
                        return;
                    }
                }
            }

            if (useTwoColumns) {
                setCurrentGroup(categoryGroups[0]);
                return;
            }
        }

        setCurrentGroup(null);
    }, [categoryGroups])

    const openServicePage = (service: ServiceGroup) => {
        setCurrentGroup(service);
    }

    return (
        <Box sx={{ mt: "10px" }}>
            <BreadcrumbsBar to={"selectedCategory"} currentPageName={category?.name} />
            {categoryGroups && categoryGroups.length === 0 && <NoElements />}
            {categoryGroups && categoryGroups.length > 0 &&
                (<Box sx={{
                    display: "flex",
                    maxWidth: !useTwoColumns ? "410px" : null,
                    flexDirection: useTwoColumns ? "row" : "column",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    p: useTwoColumns ? "10px" : "0px",
                    width: "auto",
                    mx: !useTwoColumns ? "auto" : null
                }}>
                    <ImagesGrid item container alignContent="flex-start" >
                        {
                            categoryGroups.map((service, index) => (
                                <Box key={service.id} sx={{
                                    p: "10px",
                                    backgroundColor: "white",
                                    borderRadius: "15px",
                                    m: service === currentGroup ? "2px" : "5px",
                                    borderStyle: service === currentGroup ? "solid" : null,
                                    borderColor: "paulini.orange",
                                    borderWidth: "3px",
                                    display: !useTwoColumns && currentGroup ? "none" : null,
                                    width: "380px",
                                    height: "250px"
                                }}>
                                    <ServiceLogoItem
                                        key={index}
                                        service={service}
                                        isButton={true}
                                        open={() => openServicePage(service)} />
                                </Box>
                            ))}
                    </ImagesGrid>
                    <Divider orientation="vertical" flexItem
                        sx={{
                            borderRightWidth: "5px",
                            width: "10px",
                            display: !useTwoColumns ? "none" : null
                        }} />
                    <Box sx={{ minWidth: "0px", width: "100%" }}>
                        {currentGroup && (<>
                            {!useTwoColumns && (
                                <Box sx={{ position: "relative", height: "250px" }}>
                                    <IconButton
                                        sx={{ position: "absolute", mt: "5px", zIndex: 1 }}
                                        onClick={() => setCurrentGroup(null)}>
                                        <ArrowBackIcon sx={{ color: "white" }} />
                                    </IconButton>
                                    <ServiceLogoItem service={currentGroup} />
                                </Box>)
                            }
                            <ServicesList serviceGroup={currentGroup} serviceCategory={category}></ServicesList>
                            <StickyCartBar sx={{ mx: "16px", mb: "10px" }} />
                        </>)
                        }
                    </Box>
                </Box >)
            }
        </Box >
    )
}