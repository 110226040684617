import { Grid, ImageListItem, ImageListItemBar, ListItem, Typography } from "@mui/material";
import { apiKey, appStyleMode, imagesUri } from "../../helpers/clientConfigs";
import tag from "../../logo/tag.png"
import { ServiceGroup } from "orderme-api-integration-client";
import { ImageLoader } from "../imgLoader/imgLoader"
import { DiscountImage, DiscountTypography } from "./serviceLogoItemStyles";
import i18next from "i18next";
import { AppStyle } from "../../helpers/appStyle";

interface ServiceLogoItemProps {
  service: ServiceGroup;
  isButton?: boolean;
  open?: () => void;
  imageWidth?: number;
}

declare let window: any;

export function ServiceLogoItem(props: ServiceLogoItemProps) {
  const { service, isButton, open, imageWidth } = props;

  const toggleButton = () => {
    if (isButton) {
      return true as true;
    }
  }

  const GetImageWidth = () => {
    if (imageWidth)
      return imageWidth;
    return appStyleMode === AppStyle.Mobile && window.innerWidth > 500 ? window.innerWidth : 500;
  }

  return (

    <ImageListItem sx={{
      mb: appStyleMode === AppStyle.Mobile ? "3px" : null,
      width: "100%",
      height: "100% !important",
    }}>
      <ImageLoader sx={{ display: "block", position: "absolute", objectFit: "cover", height: "100%", width: "100%" }}
        src={`${imagesUri}/${service.logoId}?orderme-api-key=${apiKey}&width=${GetImageWidth()}`}
        alt={service.name} />
      <ListItem sx={{ width: "100%", height: "100%" }} button={toggleButton()} onClick={() => isButton && open()}>
        {service.maxDiscount && (
          <>
            <DiscountImage alt="" src={tag} />
            <DiscountTypography> -{service.maxDiscount} %</DiscountTypography>
          </>
        )}

        <ImageListItemBar position="bottom"
          sx={{
            background:
              'linear-gradient(to top, rgba(0,0,0,0.5) 0%, ' +
              'rgba(0,0,0,0.15) 60%, rgba(0,0,0,0) 100%)',
          }}
          title={
            <Grid container direction="row" justifyContent="space-between">
              <Typography>{service.name}</Typography>
              <Typography sx={{ fontWeight: "lighter" }}>
                {i18next.t('services:pricesFrom', { price: (service.pricesStartFrom ?? 0).toPrice() })}</Typography>
            </Grid>} />
      </ListItem>
    </ImageListItem>
  );
}
