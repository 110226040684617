import { Grid } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyrightAppBar, CopyrightDivider, CopyrightLink, CopyrightTypography } from "./copyrightBarStyles";
import { useNavigate } from "react-router";

export function CopyrightBar() {
    const navigate = useNavigate();
    const { t } = useTranslation('copyright');
    const [currentYear] = useState(() => new Date().getFullYear());

    return (
        <CopyrightAppBar elevation={0}
            position="static">
            <CopyrightDivider />
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
                <CopyrightIcon sx={{ fontSize: "15px", mr: "5px" }} />
                <CopyrightTypography> 2013-{currentYear} UAB PAULINI</CopyrightTypography>
            </Grid>
            <CopyrightTypography>{t('info')}</CopyrightTypography>
            <CopyrightLink onClick={() => navigate("/support")}>{t('contactUs')}</CopyrightLink>
        </CopyrightAppBar >
    );
}