import { Button } from "@mui/material";
import { styled } from '@mui/system';

export const PhoneLogoImg = styled('img')({
    aspectRatio: "1/1",
    width: "90px"
});

export const ConfirmPhoneButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.orange,
    width: "100%",
    height: 50,
    "&:disabled": {
        backgroundColor: "gray"
    },

    "&.MuiButtonBase-root:hover": {
        backgroundColor: theme.palette.paulini.orange
    },
    "&.MuiButtonBase-root:hover:disabled": {
        backgroundColor: "gray"
    }
}));

export const InputStyled = styled('input')({
    width: '150px',
    borderRadius: '10px',
    height: '30px',
    fontSize: '24px',
    padding: '3px 10px 3px 15px',
    border: 'solid gray 1px',
    margin: '10px 0 0 0',
    "&::placeholder": {
        fontStyle: "italic",
        opacity: 0.5
    }
});

export const EmailConfirmImg = styled('img')({
    aspectRatio: '1/1',
    height: '80px',
    alignSelf: 'center',
    marginLeft: '5px'
});