import { env } from "../../env";
declare let window: any;

export const openRules = () => {
  if (window.cordova) {
    window.cordova.InAppBrowser.open("https://paulini.lt/rules", "_system", "location=yes");
  }
  else {
    if (env.PUBLIC_URL)
      window.open(env.PUBLIC_URL + "/rules", "_blank");
    else
      window.open("/rules", "_blank");
  }
}