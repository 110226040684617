import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import { Grid, Typography, AccordionSummary, Divider, ListItem } from "@mui/material";
import { Municipality } from "orderme-api-integration-client";
import { LocateClient } from "../../helpers/client"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionStyled, InputBaseStyled, SelectListStyled } from "./addressBookStyles"
import { AddressBookRef } from "../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';

interface MunicipalityProps {
  setSelectedMunicipality: (addresses: Municipality) => void;
  selectedMunicipality: Municipality;
}

export const MunicipalityItem = forwardRef((props: MunicipalityProps, ref: Ref<AddressBookRef>) => {
  const locateClient: LocateClient = new LocateClient();
  const [municipalities, setMunicipalities] = useState<Municipality[]>();
  const [filteredMunicipalities, setFilteredMunicipalities] = useState<Municipality[]>();
  const [search, setSearch] = useState("");
  const { setSelectedMunicipality, selectedMunicipality: selectedMunicipality } = props;
  const { t } = useTranslation('address');

  useImperativeHandle(ref, () => ({ clearValues }));

  const clearValues = () => {
    setSearch("");
    setSelectedMunicipality(null);
  }

  useEffect(() => {
    loadMunicipalities();
  }, []);

  const loadMunicipalities = () => {
    locateClient.getMunicipalities().then(response => {
      setMunicipalities(response);
    }).catch(err => console.log("error", err));
  }

  const handleMunicipalityChange = (value: Municipality) => {
    setSelectedMunicipality(value);
    setFilteredMunicipalities([]);
    setSearch(value.name);
  }

  useEffect(() => {
    if (search === selectedMunicipality?.name) {
      return;
    }

    if (municipalities && search) {
      filterMunicipalities();
    }
    else {
      setFilteredMunicipalities([]);
    }

  }, [search, municipalities]);

  const filterMunicipalities = () => {
    let filteredMunicipalities = municipalities.filter((municipality) =>
      municipality.name.toLowerCase().includes(search.toLowerCase()));

    setFilteredMunicipalities(filteredMunicipalities.sort((a, b) => a.name.localeCompare(b.name)));
  }

  return (
    <AccordionStyled
      expanded={filteredMunicipalities?.length > 0 ? true : false} >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <InputBaseStyled placeholder={t('addMunicipality')} onChange={(e) => setSearch(e.target.value)} value={search} />
      </AccordionSummary>
      <SelectListStyled>
        {filteredMunicipalities?.map((municipality, index) => {
          return (
            <Grid key={index}>
              <ListItem selected={selectedMunicipality?.code === municipality?.code ? true : false} button onClick={() => handleMunicipalityChange(municipality)}>
                <Typography sx={{ fontSize: "20px" }}> {municipality.name}</Typography>
              </ListItem>
              <Divider />
            </Grid>
          );
        })}
      </SelectListStyled>
    </AccordionStyled>
  );
});