import { useEffect, useState } from "react";
import { Grid, Typography, Breadcrumbs, Link, SxProps, Theme, } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { appStyleMode } from "../../helpers/clientConfigs";
import { AppStyle } from "../../helpers/appStyle";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import i18next from '../../i18n/i18n'
import { updateDocumentTitle } from "../../helpers/documentHelper";

export interface BreadcrumbsBarProps {
    currentPageName?: string;
    to: string;
    sx?: SxProps<Theme>;
}

const breadcrumbNameMap: { [key: string]: string } = {
    "orderHistory": i18next.t("order:history"),
    "salesPoints": i18next.t("salesPoint:salesPoints"),
    "search": i18next.t("services:allServices"),
    "partners": i18next.t("info:partners"),
    "cart": i18next.t("payment:cart"),
    "whereTo": i18next.t("payment:whereToDeliver"),
    "orderConfirmation": i18next.t("payment:orderConfirmation"),
    "paymentMethods": i18next.t("payment:paymentMethods"),
    "achievements": i18next.t("achievement:achievements"),
    "register": i18next.t("account:registration"),
    "addressBook": i18next.t("address:addressBook"),
    "accSettings": i18next.t("account:settings"),
    "credits": i18next.t("account:creditsAndCoupons"),
    "rules": i18next.t("info:rules"),
    "businessLocker": i18next.t('salesPoint:businessLocker'),
    "aboutUs": i18next.t('info:aboutUs'),
    "privacyPolicy": i18next.t('info:privacyPolicy'),
    "news": i18next.t('news:menuTitle'),
    "support": i18next.t('info:contactUs'),
};

export function BreadcrumbsBar(props: BreadcrumbsBarProps) {
    const navigate = useNavigate();
    const { currentPageName, to, sx = [] } = props;
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>();

    useEffect(() => {
        if (to) {
            const items = to.split('/');
            setBreadcrumbs(items);

            const lastItem = items.at(-1);
            if (lastItem in breadcrumbNameMap)
                updateDocumentTitle(breadcrumbNameMap[lastItem]);
        }
    }, [to]);

    useEffect(() => {
        if (currentPageName)
            updateDocumentTitle(currentPageName);
    }, [currentPageName, to]);

    const getContent = () => {
        if (appStyleMode === AppStyle.Mobile)
            return null;

        return getWebsiteContent();
    }

    const getWebsiteContent = () => {
        return (
            <Grid
                sx={[
                    {
                        width: "100%",
                        color: "white",
                        maxWidth: "1000px",
                        mb: "10px"
                    },
                    ...(Array.isArray(sx) ? sx : [sx])
                ]}>
                {breadcrumbs && (
                    <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon sx={{ color: "white" }} fontSize="medium" />} >
                        <Link key="breadcrumbs-paulini" underline="hover" color="white" component="button" onClick={() => navigate("/home")}>
                            PAULINI
                        </Link>
                        {
                            breadcrumbs.map((breadcrumb, index) => {
                                const name = breadcrumbNameMap[breadcrumb];
                                const key = "breadcrumbs-" + index;
                                return index === breadcrumbs.length - 1 ?
                                    (<Typography key={key} sx={{ color: "white", textTransform: 'uppercase' }} >{currentPageName ?? name}</Typography>) :
                                    (<Link key={key} underline="hover" color="white" component="button" onClick={() => navigate("/" + breadcrumb)}>
                                        {name.toUpperCase()}
                                    </Link>)
                            })
                        }
                    </Breadcrumbs>)
                }
            </Grid >
        );
    }

    return getContent();
}