import { Card, CardContent, CardHeader, Grid, List, Typography } from "@mui/material";
import { apiKey, appStyleMode, imagesUri } from "../../helpers/clientConfigs";
import { NearestSalesPoint } from "orderme-api-integration-client";
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { CardElement } from "./cardElement"
import { ImageLoader } from "../imgLoader/imgLoader";
import { useTranslation } from 'react-i18next';
import { AppStyle } from "../../helpers/appStyle";
import { HtmlViewer } from "../../controls/html/htmlViewer";

declare let window: any;

enum MapType {
    Call,
    Location,
    Direction
}

export interface PointInfoProps {
    salesPoint: NearestSalesPoint;
}

export function PointInfo(props: PointInfoProps) {
    const { salesPoint } = props;
    const { t } = useTranslation('salesPoint');

    const openBrowser = (type: MapType) => {
        var url: string = null;

        switch (type) {
            case MapType.Call:
                url = `tel:${salesPoint.value.phone}`;
                break;
            case MapType.Direction:
                url = salesPoint.value.location && `https://www.google.com/maps/dir/?api=1&destination=${salesPoint.value.location.latitude}%2C${salesPoint.value.location.longitude}`;
                break;
            case MapType.Location:
                url = salesPoint.value.location && `http://maps.google.com/?q=${salesPoint.value.location.latitude},${salesPoint.value.location.longitude}`;
                break;
        }

        if (!url) {
            return;
        }

        if (window.cordova) {
            window.cordova.InAppBrowser.open(url, '_system');
        }
        else {
            window.open(url, '_system');
        }
    }

    const getDirectionsText = () => {
        var directionsText: string = t('directionsText');

        if (salesPoint.distanceInMeters) {
            directionsText += ` (${(salesPoint.distanceInMeters / 1000).toFixed(2)} km)`;
        }

        return directionsText;
    }

    const gridStyleWebsite = {
        borderRadius: "0px 0px 4px 4px",
        maxWidth: "1200px",
        margin: "auto",
        backgroundColor: "white",
        color: "black",
        pt: "10px"
    } as const;

    const gridStyleMobile = {
        margin: "5px 5px 5px 15px",
        width: "auto"
    } as const;

    return (
        <>
            {salesPoint &&
                (<Grid
                    sx={appStyleMode === AppStyle.Paulini ? gridStyleWebsite : gridStyleMobile}
                    container
                    direction={appStyleMode === AppStyle.Mobile ? "column" : "row"}
                    justifyContent="stretch">
                    <Grid xs
                        sx={{
                            width: "auto",
                            margin: "10px",
                        }}
                        container
                        direction="row"
                        justifyContent="space-around">
                        <Grid sx={{ width: "auto", display: salesPoint.value.offices.every(office => office.logoId === null) && "none" }} container direction="column">
                            {salesPoint.value.offices.map((office, index) => {
                                return (
                                    <ImageLoader
                                        key={index}
                                        sx={{ height: "110px", margin: "5px" }}
                                        src={`${imagesUri}/${office?.logoId}?orderme-api-key=${apiKey}&height=150`}
                                        alt={salesPoint.value.name + " " + index} />)
                            })}
                        </Grid>
                        {salesPoint.value.workingHoursInfo && (
                            <HtmlViewer content={salesPoint.value.workingHoursInfo.replace(/\n/g, '<br />')} />
                        )}
                    </Grid>
                    <Grid xs sx={{
                        mx: "10px",
                    }}>
                        <List >
                            <CardElement text={getDirectionsText()} action={() => openBrowser(MapType.Direction)} icon={<DirectionsOutlinedIcon />} />
                            <CardElement text={salesPoint.value.name} action={() => openBrowser(MapType.Location)} icon={<LocationOnIcon />} />
                            <CardElement text={t('call', { phone: salesPoint.value.phone })} action={() => openBrowser(MapType.Call)} icon={<PhoneAndroidOutlinedIcon />} />
                            {salesPoint.value.serviceStaff && salesPoint.value.serviceStaff.length > 0 && (<CardElement text={t('workingStaff', { staff: salesPoint.value.serviceStaff?.join() })} isButton={false} icon={<AccountCircleIcon />} />)}
                        </List>
                        <Card sx={{ display: !salesPoint.value.info && "none", mb: "10px" }}>
                            <CardHeader title={<Typography sx={{ textAlign: "center" }}>{t('clientsInfo')}</Typography>} sx={{ backgroundColor: "paulini.blue", color: "white" }} />
                            <CardContent >
                                <Grid container direction="column">
                                    <Typography sx={{ textAlign: "center" }}>{salesPoint.value.info}</Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>)
            }
        </>
    );

}