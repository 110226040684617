import { AppBar, Divider, Link, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const CopyrightAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.paulini.blue,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px"
}));

export const CopyrightTypography = styled(Typography)({
    fontSize: "15px",
});

export const CopyrightDivider = styled(Divider)({
    border: "1px solid rgba(255, 255, 255, 0.2)",
    width: "100%",
    marginBottom: "10px",
});

export const CopyrightLink = styled(Link)({
    marginTop: "5px",
    marginBottom: "5px",
    color: "white",
    textDecoration: "underline",
    '&:hover': {
        cursor: "pointer",
    }
});