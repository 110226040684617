import { Grid } from "@mui/material";
import { DialogProps } from "../../../interfaces/interfaces"
import { CustomerClient } from "../../../helpers/client";
import { Popup } from "../../popup/popup";
import { ConfirmButton } from "./accountSettingsStyles";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "../../../redux/hooks";
import { forceLoginAgain, logout } from "../../../redux/reducers/authReducer";
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";

export function CustomerDelete(props: DialogProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('account');
  const { onClose, open } = props;
  const customersClient: CustomerClient = new CustomerClient();

  const removeUser = () => {
    customersClient.delete().then(async () => {
      if (appStyleMode === AppStyle.Mobile)
        dispatch(forceLoginAgain());
      else {
        dispatch(logout());
      }
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Popup open={open} onClose={onClose} dialogTitle={t('deleteAccountConfirmation')} dialogActions={
      <Grid sx={{ height: "80px" }} container direction="row" justifyContent="space-evenly" alignItems="center">
        <ConfirmButton onClick={() => removeUser()} variant="contained"> {t('confirmationYes')}</ConfirmButton>
        <ConfirmButton onClick={() => onClose()} variant="contained"> {t('confirmationNo')}</ConfirmButton>
      </Grid>} />
  );
}