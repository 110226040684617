import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrayTypography } from "./shoppingCartStyles";
import { DeliveryType, SalesPoint } from "orderme-api-integration-client";
import { SalePointClient } from "../../helpers/client";

export function DeliveryInfo() {
    const salePointClient: SalePointClient = new SalePointClient();
    const deliveryType = useAppSelector((state: RootState) => state.pickupState.deliveryType);
    const orderDestination = useAppSelector((state: RootState) => state.pickupState.orderDestination);
    const [salesPoint, setSalePoint] = useState<SalesPoint>(null);
    const { t } = useTranslation('payment');

    useEffect(() => {
        if (orderDestination?.knownId) {
            salePointClient.getById(orderDestination.knownId)
                .then((response) => {
                    setSalePoint(response);
                })
                .catch((ex) => console.error(ex));

            return;
        }
        else {
            setSalePoint(null);
        }
    }, [orderDestination]);

    const getDeliveryFullName = (salesPoint: SalesPoint) => {
        if (!salesPoint)
            return "";

        return `${salesPoint.name}, ${salesPoint.address} ${salesPoint.city}`;
    }

    return (
        <>
            {
                deliveryType === DeliveryType.SalesPoint &&
                (orderDestination.knownId ?
                    <>
                        <GrayTypography>{t('deliveryToSalesPoint')}</GrayTypography>
                        <GrayTypography>{getDeliveryFullName(salesPoint)}</GrayTypography>
                    </> :
                    <GrayTypography>{t('deliveryToAnySalesPoint')}</GrayTypography>)
            }
            {
                deliveryType === DeliveryType.Address &&
                <>
                    <GrayTypography>{t('deliveryFromHome')}</GrayTypography>
                    <GrayTypography>{orderDestination.address.fullAddress}</GrayTypography>
                    {
                        orderDestination.address.apartmentNumber &&
                        <GrayTypography>{t('apartmentNo', { apartmentNo: orderDestination.address.apartmentNumber })}</GrayTypography>
                    }
                </>
            }
            {
                deliveryType === DeliveryType.Wardrobe &&
                (orderDestination.knownId ?
                    <>
                        <GrayTypography>{t('deliveryToWardrobe')}</GrayTypography>
                        <GrayTypography>{getDeliveryFullName(salesPoint)}</GrayTypography>
                    </> :
                    <GrayTypography>{t('deliveryToAnySalesPoint')}</GrayTypography>)
            }
        </>
    );
}