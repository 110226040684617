import Cookies from "js-cookie";
import { ITokenStorage } from "../interfaces/interfaces";
import { AppStyle } from "./appStyle";
import { appStyleMode } from "./clientConfigs";

const accessTokenKey: string = "access";
const refreshTokenKey: string = "refresh";

export class TokenStorage {
    private static _instance: ITokenStorage;

    public static get Instance() {
        if (this._instance != null)
            return this._instance;

        if (appStyleMode === AppStyle.Mobile)
            this._instance = new TokenLocalStorage();
        else
            this._instance = new TokenCookieStorage();

        return this._instance;
    };
}

class TokenCookieStorage implements ITokenStorage {
    getAccessToken() {
        return Cookies.get(accessTokenKey);
    };

    getRefreshToken() {
        return Cookies.get(refreshTokenKey);
    };

    update(accessToken: string, refreshToken: string, remember: boolean) {
        if (accessToken && refreshToken) {
            const expires = remember ? 7 : null;
            Cookies.set(accessTokenKey, accessToken, { secure: true, expires: expires });
            Cookies.set(refreshTokenKey, refreshToken, { secure: true, expires: expires });
        }
        else {
            this.clear();
        }
    }

    clear() {
        Cookies.remove(accessTokenKey);
        Cookies.remove(refreshTokenKey);
    };
}

class TokenLocalStorage implements ITokenStorage {
    getAccessToken() {
        return localStorage.getItem(accessTokenKey);
    };

    getRefreshToken() {
        return localStorage.getItem(refreshTokenKey);
    };

    update(accessToken: string, refreshToken: string, _remember: boolean) {
        if (accessToken && refreshToken) {
            localStorage.setItem(accessTokenKey, accessToken);
            localStorage.setItem(refreshTokenKey, refreshToken);
        }
        else {
            this.clear();
        }
    };

    clear() {
        localStorage.removeItem(accessTokenKey);
        localStorage.removeItem(refreshTokenKey);
    };
}