import { useEffect } from 'react';
import { useAppDispatch } from './redux/hooks'
import { finishLoading, setDisconnected, verifyAppVersion } from "./redux/reducers/appLoaderReducer"
import { StartPage } from "./components/initialPage/initialPage"
import { useNavigate } from 'react-router';
import { CustomerClient, ServiceClient } from "./helpers/client"
import './helpers/extensions';

declare let window: any;

export function Init() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerClient: CustomerClient = new CustomerClient();
  const servicesClient: ServiceClient = new ServiceClient();

  if (window.cordova) {
    document.addEventListener("offline", function () { dispatch(setDisconnected(true)) });
    document.addEventListener('online', function () { dispatch(setDisconnected(false)) });
  }
  else {
    window.addEventListener('offline', function () { dispatch(setDisconnected(true)) });
    window.addEventListener('online', function () { dispatch(setDisconnected(false)) });
  }

  if (window.MobileAccessibility) {
    window.MobileAccessibility.usePreferredTextZoom(false);
  }

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    testConnectivity();
    testAuthorization();
  }

  const testConnectivity = () => {
    Promise
      .all([
        dispatch(verifyAppVersion()),
        servicesClient.getCategories()])
      .then(() => {
        setTimeout(function () {
          navigate("/home", { replace: true });
          dispatch(finishLoading());
        }, 1000);
      }).catch((err) => {
        console.log(err);
      });
  }

  const testAuthorization = () => {
    customerClient.getInfo().then((response) => {
    })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <StartPage />
  );
}


