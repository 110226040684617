import { Box } from "@mui/material";
import { Banner } from "../../banner/banner";
import { Login } from "./login";

export function LoginPage() {
    return (
        <Box>
            <Banner />
            <Login />
        </Box>
    );
}