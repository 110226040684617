import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { Typography, AccordionSummary, ListItem } from "@mui/material";
import { Address } from "orderme-api-integration-client";
import { LocateClient } from "../../helpers/client";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionStyled, InputBaseStyled, SelectListStyled } from "./addressBookStyles";
import { AddressBookRef, StreetProps } from "../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';

export const StreetItem = forwardRef((props: StreetProps, ref: Ref<AddressBookRef>) => {
  const locateClient: LocateClient = new LocateClient();
  const [lookupValue, setLookupValue] = useState<string>('');
  const [addresses, setAddresses] = useState<Address[]>();
  const { municipality, setNewAddress } = props;
  const { t } = useTranslation('address');

  useImperativeHandle(ref, () => ({ clearValues }));

  const clearValues = () => {
    setLookupValue('');
    setNewAddress(null);
  }

  const handleAddressChange = (e: any) => {
    if (!municipality)
      return;

    searchAddress(e.target.value);
    setLookupValue(e.target.value);
  }

  const searchAddress = (lookup: string) => {
    locateClient.findAddresses(municipality.code, lookup).then(response => {
      setAddresses(response);
    }).catch(err => console.log("err", err));
  }

  const setAddress = (address: Address) => {
    setNewAddress(address);
    setLookupValue(address.fullAddress);
    setAddresses([]);
  }

  return (
    <AccordionStyled expanded={addresses?.length > 0 ? true : false}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <InputBaseStyled placeholder={t('streetNameInput')} onChange={handleAddressChange} value={lookupValue} />
      </AccordionSummary>
      <SelectListStyled>
        {addresses?.map((address, index) => {
          return (
            <ListItem button onClick={() => setAddress(address)} key={index}>
              <Typography>{address.fullAddress}</Typography>
            </ListItem>
          );
        })}
      </SelectListStyled>
    </AccordionStyled>
  );
});