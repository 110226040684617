import { Grid } from "@mui/material";
import { HomeBar } from "../appBars/homeBar/homeBar"
import { ServiceTabs } from "../serviceTabs/serviceTabs"
import { BottomCartBar } from "../appBars/cartBar/bottomCartBar";
import { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { verifyAppVersion } from "../../redux/reducers/appLoaderReducer";

export function MobileHomePage() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(verifyAppVersion());
  }, []);
  return (
    <Grid>
      <Grid sx={{ overflow: "hidden" }}>
        <HomeBar />
        <ServiceTabs />
        <BottomCartBar />
      </Grid>
    </Grid>
  );
}