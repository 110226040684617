import { CustomerInfo, CustomerLoginInfo } from "orderme-api-integration-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCustomerNumber } from "../../helpers/localStorageService";
import Cookies from "js-cookie";

interface CustomerState {
    loginInfo: CustomerLoginInfo;
    customerInfo: CustomerInfo;
    isPhoneConfirmationSent: boolean;
}

const initialState = {
    loginInfo: {} as CustomerLoginInfo,
    customerInfo: {} as CustomerInfo,
    isPhoneConfirmationSent: Cookies.get("sms_confirm_pending") === "true"
} as CustomerState;

const customerSlice = createSlice({
    name: 'customer',
    initialState: initialState,
    reducers: {
        updateCustomer: (state, action: PayloadAction<CustomerLoginInfo>) => {
            state.loginInfo = action.payload;
            state.customerInfo = action.payload?.customers[getCustomerNumber()];
            if (action.payload.phoneConfirmed) {
                removePendingPhoneConfirmation(state);
            }
        },
        updateCustomerInfo: (state, action: PayloadAction<CustomerInfo>) => {
            state.customerInfo = action.payload;
            const addresses = action.payload?.addresses;
            if (addresses?.length > 0)
                state.customerInfo.addresses = addresses.filter(x => x.isValid);

        },
        waitPhoneConfirmation: (state) => {
            state.isPhoneConfirmationSent = true;
            Cookies.set("sms_confirm_pending", "true", { expires: 1 });
        },
        confirmPhone: (state) => {
            removePendingPhoneConfirmation(state);
        },
        clearCustomer: (state) => {
            state.customerInfo = initialState.customerInfo;
            state.loginInfo = initialState.loginInfo;
            removePendingPhoneConfirmation(state);
        }
    },
})

function removePendingPhoneConfirmation(state: CustomerState) {
    state.isPhoneConfirmationSent = false;
    Cookies.remove("sms_confirm_pending");
}

export const { updateCustomer, updateCustomerInfo, clearCustomer, waitPhoneConfirmation, confirmPhone } = customerSlice.actions
export default customerSlice.reducer